import types from "../types/types";

export const defaultState = {
  lots: [],
  pagination: null,
  currentMovement: null,
  currentLot: null,
  lotHeader: null,
  lotRows: [],
  movements: [],
  modal: {
    add: false,
    delete: false,
    backload: false,
    sell: false,
    unsell: false,
    tag: false,
    view: false,
    edit: false,
    lot_delete: false,
    createMovement: false,
  },
  openDetails: false,
  balance: null,
  movingData: null,
}

function lotReducer(state, action) {
  switch (action.type) {
    case types.LOT_LOAD_LOTS: {
      return {
        ...state,
        lots: [...action.lots],
      }
    }
    case types.LOT_LOAD_PAGINATION: {
      return {
        ...state,
        pagination: action.pagination
      }
    }
    case types.LOT_SET_CURRENT_LOT: {
      return {
        ...state,
        currentLot: action.lot,
      }
    }
    case types.LOT_SET_CURRENT_MOVEMENT: {
      return {
        ...state,
        currentMovement: action.movement,
      }
    }
    case types.LOT_OPEN_MODAL: {
      switch (action.modal) {
        case 'add':
          return {
            ...state,
            modal: {
              ...state.modal,
              add: action.status,
            }
          }
        case 'delete':
          return {
            ...state,
            modal: {
              ...state.modal,
              delete: action.status,
            }
          }
        case 'backload':
          return {
            ...state,
            modal: {
              ...state.modal,
              backload: action.status,
            }
          }
        case 'sell':
          return {
            ...state,
            modal: {
              ...state.modal,
              sell: action.status,
            }
          }
        case 'unsell':
          return {
            ...state,
            modal: {
              ...state.modal,
              unsell: action.status,
            }
          }
        case 'tag':
          return {
            ...state,
            modal: {
              ...state.modal,
              tag: action.status,
            }
          }
        case 'view':
          return {
            ...state,
            modal: {
              ...state.modal,
              view: action.status,
            }
          }
        case 'edit':
          return {
            ...state,
            modal: {
              ...state.modal,
              edit: action.status,
            }
          }
        case 'lot_delete':
          return {
            ...state,
            modal: {
              ...state.modal,
              lot_delete: action.status
            }
          }
        case 'createMovement':
          return {
            ...state,
            modal: {
              ...state.modal,
              createMovement: action.status
            }
          }
        default:
          throw new Error("Unhandled modal type: " + action.modal);
      }
    }
    case types.LOT_LOAD_CURRENT_MOVEMENTS: {
      return {
        ...state,
        movements: action.movements
      }
    }
    case types.LOT_OPEN_DETAILS: {
      return {
        ...state,
        openDetails: action.status,
      }
    }
    case types.LOT_LOAD_HEADER: {
      return {
        ...state,
        lotHeader: action.lotHeader,
      }
    }
    case types.LOT_LOAD_ROWS: {
      console.log(action.rows);
      return {
        ...state,
        lotRows: [...action.rows],
      }
    }
    case types.LOT_LOAD_BALANCE: {
      return {
        ...state,
        balance: action.balance,
      }
    }
    case types.LOT_UPDATE_MOVEMENT: {

      console.log(state.movements);

      let _movements = updateNode(state.movements, action.movement);

      return {
        ...state,
        movements: {..._movements},
        currentMovement: action.movement,
      }
    }
    case types.LOT_LOAD_MOVING_DATA: {
      return {
        ...state,
        movingData: action.data
      }
    }
    default:
      throw new Error("Unhandled action type: " + action.type);
  }
}

function updateNode(tree, movement) {
  if (tree.idnodo === movement.idnodo) {
    return {
      ...movement,
      children: tree.children,
    }
  } else {
    if (tree.children)
      return {
        ...tree,
        children: [...tree.children.map((child) => updateNode(child, movement))]
      }
    else return {...tree};
  }
}

export default lotReducer;
