import React from 'react';
import {useReport} from "../../../utilities/contexts/report-context";
import HorizontalLabelledField from "../../UI/Fields/HorizontalLabelledField";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import {dateToFileName, formatInt, formatPrice, nameToFileName} from "../../../utilities/data/parser";
import PrintButton from "../../UI/PrintButton";
import RowAligned from "../../UI/Structure/RowAligned";
import {CSVLink} from "react-csv";
import CsvButton from "../../UI/CSVButton";

const headers = [
    {label: "Nr Lotto", key: "nrlotto"},
    {label: "Provenienza", key: "buying_article"},
    {label: "Articolo", key: "sales_article"},
    {label: "Cliente", key: "client"},
    {label: "Resa", key: "performance"},
    {label: "Costo Mq", key: "costs_per_mq"},
    {label: "Ve/Mq", key: "sold_per_mq"},
    {label: "Diff %", key: "diff_percentage"},
    {label: "N. Mq Vend.", key: "sold_mq"},
    {label: "Tot costo", key: "total_cost"},
    {label: "Tot Vend", key: "total_profit"},
    {label: "Margine", key: ""},
    {label: "Tot Nr. Pz", key: "nrpz"}
];

const SalesTotals = ({onClick}) => {

    const {salesData, salesFormData} = useReport();

    const getCsvFileName = () => {
        let start = dateToFileName(salesFormData.date_from)
        let end = dateToFileName(salesFormData.date_to);
        let client = salesFormData?.client?.name ? nameToFileName(salesFormData.client.name)  + "_" : "";
        return `resoconto_vendite_${client}dal_${start}_al_${end}`;
    }

    return (
        <div className="flex flex-row justify-between gap-2 border-t border-black p-1">
            <RowAligned>
                <PrintButton onClick={onClick}/>
                <CSVLink data={salesData.list}
                         filename={getCsvFileName()}
                         separator={";"}
                         headers={headers}>
                    <CsvButton />
                </CSVLink>
            </RowAligned>

            <VerticalLabelledField label="Totali">
                <div className="flex flex-row gap-2">
                    <HorizontalLabelledField label="Nrpz">
                        {formatInt(salesData.totals?.sold_leathers?.nrpz)}
                    </HorizontalLabelledField>
                    <HorizontalLabelledField label="Costi">
                        {formatPrice(salesData.totals?.sold_leathers?.costs)}€
                    </HorizontalLabelledField>
                    <HorizontalLabelledField label="Vendite">
                        {formatPrice(salesData.totals?.sold_leathers?.sales)}€
                    </HorizontalLabelledField>
                    <HorizontalLabelledField label="Profitti">
                        {formatPrice(salesData.totals?.sold_leathers?.profit)}€
                    </HorizontalLabelledField>
                </div>
            </VerticalLabelledField>
        </div>
    );
};

export default SalesTotals;