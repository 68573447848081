import React, {useEffect, useRef} from 'react';
import Button from "../UI/Button";
import BaseInput from "../UI/BaseInput";
import Select from "../UI/Select";
import Textarea from "../UI/Textarea";
import VerticalLabelledField from "../UI/Fields/VerticalLabelledField";
import FormTitle from "../Lots/Movements/Creation/UI/FormTitle";

const ArticleForm = ({selected, onSubmit}) => {
    const formRef = React.useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        let nomeart = formRef.current.nomeart.value;
        let codart = formRef.current.codart.value;
        let descrizione = formRef.current.descrizione.value;
        let idtipoart = formRef.current.idtipoart.value;

        if (selected) {
            let params = {
                nomeart,
                codart,
                descrizione,
                idtipoart
            }
            onSubmit && onSubmit(params);
        } else {
            let params = new URLSearchParams();
            params.append('nomeart', nomeart);
            params.append('codart', codart);
            params.append('descrizione', descrizione);
            params.append('idtipoart', idtipoart);
            onSubmit && onSubmit(params);
        }
    }

    useEffect(() => {
        if (selected) {
            if (selected?.nomeart) formRef.current.nomeart.value = selected.nomeart;
            if (selected?.codart) formRef.current.codart.value = selected.codart;
            if (selected?.descrizione) formRef.current.descrizione.value = selected.descrizione;
            if (selected?.idtipoart) formRef.current.idtipoart.value = selected.idtipoart;
        }
    }, [selected]);


    return (
        <div>
            <FormTitle>{selected ? "Modifica" : "Crea"} articolo</FormTitle>
            <form onSubmit={handleSubmit}
                  className="flex flex-col gap-2"
                  ref={formRef}
                  style={{minWidth: "300px"}}>
                <VerticalLabelledField label="Nome">
                    <BaseInput placeholder="Nome" name="nomeart"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Codice">
                    <BaseInput placeholder="Codice" name="codart"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Descrizione">
                    <Textarea placeholder="Descrizione" name="descrizione"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Tipologia">
                    <Select name="idtipoart">
                        <option value="2">acquisto</option>
                        <option value="3">c/to lavoro</option>
                        <option value="1">vendita</option>
                    </Select>
                </VerticalLabelledField>
                <Button>{selected ? "Modifica" : "Crea"}</Button>
            </form>
        </div>
    );
};

export default ArticleForm;
