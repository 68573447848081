import React from 'react';
import ClientRow from "./ClientRow";
import {BiPlus} from "react-icons/bi";

const ClientTable = ({clients, onCreate, onEdit, onDelete}) => {
    const handleCreate = () => {
        onCreate && onCreate();
    }
    const handleEdit = (idcl) => {
        onEdit && onEdit(idcl);
    }
    const handleDelete = (idcl) => {
        onDelete && onDelete(idcl);
    }

    return (
        <table className="w-full max-h-screen text-left">
            <thead>
            <tr className="border-b border-gray-600">
                <th className="pl-2">Ragione sociale</th>
                <th className="pl-2">Contatto</th>
                <th>
                    <div className="flex flex-row justify-center items-center w-full">
                        <BiPlus
                            onClick={handleCreate}
                            title={'Nuovo cliente'}
                            className="cursor-pointer hover:text-green-400"/>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody className="max-h-screen-50 overflow-y-auto w-full ">
            {clients && clients.length > 0 &&
                clients.map((client, i) =>
                    <ClientRow
                        client={client}
                        key={i}
                        onClick={handleEdit}
                        onDelete={handleDelete}
                    />
                )
            }
            </tbody>
            <tfoot>
            </tfoot>
        </table>
    )
}

export default ClientTable;
