import React from 'react';
import RowAligned from "./Structure/RowAligned";
import {FaFileCsv} from "react-icons/fa";

const CsvButton = ({...props}) => {
    return (
        <div className="flex flex-row items-center cursor-pointer bg-blue-100 hover:bg-blue-300 p-1 rounded gap-1" {...props}>
            <FaFileCsv />
            Esporta CSV
        </div>
    );
};

export default CsvButton;