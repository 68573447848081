import React, {useEffect, useState} from 'react';
import BaseInput from "../../UI/BaseInput";
import HeaderSection from "./UI/HeaderSection";
import RowAligned from "../../UI/Structure/RowAligned";
import Row from "../../UI/Structure/Row";
import SelectSupplier from "../../UI/Selects/SelectSupplier";
import Button from "../../UI/Button";
import SearchSelectDocuments from "../../UI/Selects/SearchSelectDocuments";
import SelectMeasureUnits from "../../UI/Selects/SelectMeasureUnits";
import FormField from "../../UI/Form/FormField";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";

const HeaderEditForm = ({header, onUndo, onSubmit, suggested = "", ...props}) => {

    const [changedNrLotto, setChangedNrLotto] = useState(suggested ? suggested : ((header && header.nrlotto) ? header.nrlotto : ""));
    const [supplier, setSupplier] = useState(header && header.supplier && header.supplier !== 0 ? header.supplier : null);
    const [document, setDocument] = useState(header && header.document ? {idtipodoc: header.document.type.idtipodoc, nome: header.document.type.nome ?? header.document.type.name}: null);
    const [measureUnit, setMeasureUnit] = useState(header && header.um ? header.um.id : "");

    const formRef = React.useRef();

    const handleDocChange = (value) => {
        if(value && value.id && value.name) {
            setDocument({idtipodoc: value.id, nome: value.name});
        } else setDocument(null);

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let idforn = supplier ? supplier.id || supplier.idforn : null;
        let note = formRef.current.note.value;
        let idtipodoc = document ? document.idtipodoc : null;
        let datadocfor = formRef.current.datadocfor.value;
        let nrdocfor = formRef.current.nrdocfor.value;
        let spesedazio = formRef.current.spesedazio.value;
        let docspesedazio = formRef.current.docspesedazio.value;
        let spesedogana = formRef.current.spesedogana.value;
        let docspesedogana = formRef.current.docspesedogana.value;
        let eur_usd = formRef.current.eur_usd.value;
        let valuta = formRef.current.valuta.value;
        let venditacroste = formRef.current.venditacroste.value;
        let docvenditacroste = formRef.current.docvenditacroste.value ? formRef.current.docvenditacroste.value : null ;
        let datavenditacroste = formRef.current.datavenditacroste.value ? formRef.current.datavenditacroste.value : null;
        let daticompleti = formRef.current.daticompleti.checked? true : 0;
        let um = measureUnit ? measureUnit : "";

        let fields = {
            idforn,
            note,
            nrdocfor,
            datadocfor,
            idtipodoc,
            spesedazio,
            docspesedazio,
            spesedogana,
            docspesedogana,
            eur_usd,
            valuta,
            venditacroste,
            docvenditacroste,
            datavenditacroste,
            daticompleti,
            um,
        }

        if(!header || (changedNrLotto !== header.nrlotto)) {
            fields.nrlotto = changedNrLotto;
        }

        onSubmit && onSubmit(fields);
    }

    useEffect(() => {
        if (header && formRef.current) {
            header.supplier && setSupplier(header.supplier);
            header.document && setDocument({idtipodoc: header.document.type.idtipodoc, nome: header.document.type.nome});
            header.um && setMeasureUnit(header.um.id);
            header.nrlotto && setChangedNrLotto(header.nrlotto);
            formRef.current.note.value = header.note;
            formRef.current.nrlotto.value = header.nrlotto;
            formRef.current.nrdocfor.value = header.nrdocfor;
            formRef.current.datadocfor.value = header.datadocfor ? header.datadocfor : null;
            formRef.current.spesedazio.value = header.spesedazio;
            formRef.current.docspesedazio.value = header.docspesedazio;
            formRef.current.spesedogana.value = header.spesedogana;
            formRef.current.docspesedogana.value = header.docspesedogana;
            formRef.current.eur_usd.value = header.eur_usd;
            formRef.current.valuta.value = header.valuta;
            formRef.current.venditacroste.value = header.venditacroste;
            formRef.current.docvenditacroste.value = header.docvenditacroste;
            formRef.current.datavenditacroste.value = header.datavenditacroste;
            formRef.current.daticompleti.checked = header.daticompleti === "1";
        }
    }, [header])

    useEffect(() => {
        if(suggested)
            setChangedNrLotto(suggested);
    }, [suggested]);

    return (
        <form onSubmit={handleSubmit} ref={formRef} className="w-full flex flex-col gap-2" style={{maxWidth: "1200px"}}>
            <div className="flex flex-col md:flex-row gap-2">
                <HeaderSection label="Dati lotto" className="flex-1">
                    <Row className="items-start gap-1">
                        <VerticalLabelledField label="Nr">
                            <BaseInput name="nrlotto"
                                       placeholder="nrlotto"
                                       value={changedNrLotto}
                                       onChange={(e) => setChangedNrLotto(e.target.value)}/>
                            {suggested && suggested !== "" &&
                                <div className="text-xs font-semibold">Numero lotto suggerito: {suggested}</div>}
                        </VerticalLabelledField>
                        <VerticalLabelledField label="Fornitore" className="flex-1">
                            <SelectSupplier value={supplier} onChange={(value) => setSupplier(value)}/>
                        </VerticalLabelledField>
                    </Row>
                </HeaderSection>
                <HeaderSection label="Documento in ingresso" className="flex-1">
                    <div className="flex flex-col lg:flex-row gap-1">
                        <VerticalLabelledField label={"Nr."}>
                            <BaseInput name="nrdocfor"
                                       placeholder="nr"/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label={"Data"}>
                            <BaseInput name="datadocfor"
                                       placeholder="nr"
                                       required
                                       type="date"/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label={"Tipo"} className="flex-1">
                            <SearchSelectDocuments value={document}
                                                   onChange={handleDocChange}/>
                        </VerticalLabelledField>
                    </div>
                </HeaderSection>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
                <HeaderSection label="Spese Dogana" className="flex-1">
                    <Row>
                        <VerticalLabelledField label="Importo" className="flex-1">
                            <BaseInput name="spesedogana"
                                       placeholder="Importo"
                                       step={'any'}
                                       type="number"/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label="Riferimenti" className="flex-1">
                            <BaseInput name="docspesedogana"
                                       placeholder="Documento"/>
                        </VerticalLabelledField>
                    </Row>
                </HeaderSection>
                <HeaderSection label="Spese Dazio" className="flex-1">
                    <Row>
                        <VerticalLabelledField label="Importo" className="flex-1">
                            <BaseInput name="spesedazio"
                                       placeholder="Importo"
                                       step={'any'}
                                       type="number"/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label="Riferimenti" className="flex-1">
                            <BaseInput name="docspesedazio"
                                       placeholder="Documento"/>
                        </VerticalLabelledField>
                    </Row>
                </HeaderSection>
            </div>

            <Row className="gap-2">
                <HeaderSection label="Vendita Croste" className="flex-1">
                    <Row>
                        <VerticalLabelledField label="Importo">
                            <BaseInput name="venditacroste"
                                       placeholder="Importo"
                                       type="number"/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label="Riferimenti">
                            <BaseInput name="docvenditacroste"
                                       placeholder="Documento"/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label="Data">
                            <BaseInput name="datavenditacroste"
                                       type="date"/>
                        </VerticalLabelledField>
                    </Row>
                </HeaderSection>
                <HeaderSection label="Cambio EUR-USD" className="flex-1">
                    <RowAligned className="gap-1">
                        <label htmlFor="USD">USD</label>
                        <input name="valuta" type="radio" id="USD" value="USD"/>
                        <label htmlFor="EUR">EUR</label>
                        <input name="valuta" type="radio" id="EUR" value="EUR"/>
                    </RowAligned>

                    <div>Valore di cambio: modificare solo se la valuta è USD</div>
                    <BaseInput type="number" name="eur_usd" min="0" max="10" step="any"/>
                </HeaderSection>
            </Row>


            <HeaderSection label="Note">
                <textarea name="note"
                          placeholder="Inserisci testo"
                          className="w-full p-1 bg-black bg-opacity-5 rounded shadow-inner"/>
            </HeaderSection>

            <Row className="gap-2">
                <HeaderSection label="Dati completi" className="flex-1">
                    <RowAligned>
                        <input name="daticompleti"
                               type="checkbox" />
                        <label htmlFor="completed_data">Completati</label>
                    </RowAligned>
                </HeaderSection>
                <HeaderSection label="Unità di misura superficie" className="flex-1">
                    <SelectMeasureUnits onChange={(value) => setMeasureUnit(value)}
                        value={measureUnit}/>
                </HeaderSection>
            </Row>

            <RowAligned className="justify-around mt-2">
                {onUndo && <Button type="button" onClick={onUndo}>Annulla</Button>}
                <Button type="submit">Conferma</Button>
            </RowAligned>

        </form>
    );
};

export default HeaderEditForm;
