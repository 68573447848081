import React, {useState} from 'react';
import {useReport} from "../../../utilities/contexts/report-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetProcessingCosts} from "../../../logics/reports";
import Search from "./Search";
import CostsTable from "./CostsTable";
import Loading from "../../UI/Loading";
import CostsTotals from "./CostsTotals";

const ProcessingCosts = () => {

    const {call} = useAsync();
    const {costsData, costsFormData, loadCosts} = useReport();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        await call(
            () => doGetProcessingCosts(costsFormData.date_from, costsFormData.date_to, costsFormData.client, costsFormData.nrlotto),
            (res) => {
                if(res) {
                    if(res.grandtotals) {
                        loadCosts([], res.grandtotals);
                    }
                }

            }
        );
        setLoading(false);
    }

    return (
        <div className="flex flex-col flex-1 " style={{minHeight: "100px"}}>
            <Search onSubmit={handleSubmit} />

            <div className="flex flex-col flex-1 overflow-y-auto relative" style={{minHeight: "50px"}}>
                {loading ?
                    <Loading label="Caricamento" />
                    :
                    <CostsTable />
                }
            </div>

            {costsData &&
                <CostsTotals />
            }
        </div>
    );
};

export default ProcessingCosts;