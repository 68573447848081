import types from "../types/types";


function loadSales(list, totals) {
    return {
        type: types.REPORTS_LOAD_SALES,
        list: list,
        totals: totals,
    }
}
function setSalesForm(date_from, date_to, client, nrlotto) {
    return {
        type: types.REPORTS_SALES_SET_FORM,
        date_from,
        date_to,
        client,
        nrlotto,
    }
}

function loadCosts(list, totals) {
    return {
        type: types.REPORTS_LOAD_COSTS,
        list,
        totals,
    }
}
function setCostsForm(date_from,date_to,client,nrlotto) {
    return {
        type: types.REPORTS_COSTS_SET_FORM,
        date_from,
        date_to,
        client,
        nrlotto,
    }
}
function loadMovements(list, totals) {
    return {
        type: types.REPORTS_LOAD_MOVEMENTS,
        list,
        totals
    }
}
function setMovementsForm(date_from, date_to, nrlotto) {
    return {
        type: types.REPORTS_MOVEMENTS_SET_FORM,
        date_from,
        date_to,
        nrlotto,
    }
}
function loadJournal(list, totals) {
    return {
        type: types.REPORTS_LOAD_JOURNAL,
        list,
        totals,
    }
}
function setJournalForm(date_from, date_to, nrlotto, totals) {
    return {
        type: types.REPORTS_JOURNAL_SET_FORM,
        date_from,
        date_to,
        nrlotto,
        totals,
    }
}
function loadLedger(list, totals) {
    return {
        type: types.REPORTS_LOAD_LEDGER,
        list,
        totals,
    }
}
function setLedgerForm(date_from, date_to, nrlotto) {
    return {
        type: types.REPORTS_LEDGER_SET_FORM,
        date_from,
        date_to,
        nrlotto,
    }
}

const actions = {
    loadSales,
    setSalesForm,
    loadCosts,
    setCostsForm,
    loadMovements,
    setMovementsForm,
    loadJournal,
    setJournalForm,
    loadLedger,
    setLedgerForm,
}

export default actions;
