import {useRegistry} from "../../../utilities/contexts/registry-context";
import useAsync from "../../../utilities/customHooks/async-call";
import useToast from "../../../utilities/customHooks/toast";
import React, {useEffect, useState} from "react";
import useFilter from "../../../utilities/customHooks/filter";
import {
    doDeleteSubcontracorProcessing,
    doGetSubcontracorProcesses,
    doGetSubcontracorProcessing, doGetSubcontractor,
    doPostSubcontracorProcessing,
    doPutSubcontracorProcessing,
} from "../../../logics/registry";
import BaseInput from "../../UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import Loading from "../../UI/Loading";
import Modal from "../../UI/Modal";
import {useMatch, useNavigate, useParams} from "react-router-dom";
import SubcontractorProcessesTable from "./SubcontractorProcessesTable";
import SubcontractorProcessesForm from "./SubcontractorProcessesForm";
import {BiArrowBack} from "react-icons/bi";

const SubcontractorProcesses = () => {
    const {
        currentSubcontractor,
        setCurrentSubcontractor,
        subcontractorProcesses,
        loadSubcontractorProcesses
    } = useRegistry();
    const {call} = useAsync();
    const {toastMessage} = useToast();
    const routeParams = useParams();
    const navigate = useNavigate();
    const matchPath = useMatch('/terzisti/:idterz');

    const [loading, setLoading] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedSubcontractorProcess, setSelectedSubcontractorProcess] = useState(null);

    const filterByName = (subcontractorProcesses) => {
        return subcontractorProcesses.Nomelav.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(subcontractorProcesses, filterByName);

    //sets currentSubcontractor in registry subcontractor whose linked processes are listed
    useEffect(() => {
        if (routeParams.idterz) {
            handleGetSelectedSubcontrator().then(() =>{})
        }
    }, [routeParams.idterz]);

    const handleGetSelectedSubcontrator = async () => {
        await call(
            () => doGetSubcontractor(routeParams.idterz),
            (res) => {
                setCurrentSubcontractor(res[0]);
            },
        );
    }

    const handleCreate = () => {
        setSelectedSubcontractorProcess(null);
        setEditModalOpen(true);
    }

    const handleDelete = async (IdTerzLav) => {
        if (!window.confirm('Eliminare lavorazione?')) {
            return;
        }
        await call(
            () => doDeleteSubcontracorProcessing(IdTerzLav),
            (res) => {
                if (res) {
                    toastMessage("Lavorazione eliminata");
                    reload();
                }
            }
        )
    }
    const handleEdit = async (IdLav) => {
        await call(
            () => doGetSubcontracorProcessing(routeParams.idterz, IdLav),
            (res) => {
                if (res) {
                    setSelectedSubcontractorProcess(res);
                }
            }
        )
        setEditModalOpen(true);
    }

    const handleSubmit = async (params) => {

        if (selectedSubcontractorProcess) {
            await call(
                () => doPutSubcontracorProcessing(selectedSubcontractorProcess.idterzlav, params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Lavorazione modificata");
                        reload();
                    }
                }
            );
        } else {
            await call(
                () => doPostSubcontracorProcessing(params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Lavorazione creata");
                        reload();
                    }
                }
            );
        }
    }

    const reload = async () => {
        setLoading(true);
        setFilterText("");
        await call(
            () => doGetSubcontracorProcesses(routeParams.idterz),
            (res) => {
                loadSubcontractorProcesses(res);
            },
        )
        setLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await reload();
        }
        load();
    }, []);

    const patternMatch = () => {
        return matchPath && matchPath.pattern;
    }

    const goBack = () => {
        navigate('/terzisti/lista', {replace: true});
    }

    const goList = () => {
        navigate(`/terzisti/${routeParams.idterz}`, {replace: true});
    }

    return (
        <>
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className="flex flex-row gap-2 items-center bg-secondary bg-opacity-50 pl-2">
                    <button onClick={goBack} title="Torna alla lista"><BiArrowBack/></button>
                    <button
                        onClick={goList}
                        title="Vai a lavorazioni terzista Lotto"
                        className={`${patternMatch() ? "font-bold underline" : ""}`}
                    >
                        Lavorazioni di {currentSubcontractor?.ragsoctz}
                    </button>
                </div>
                <div className="mb-4">
                    <BaseInput
                        placeholder={'Cerca lavorazione'}
                        className={'w-full mb-1'}
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Lavorazioni elencate:</span>
                        <span>{filteredData?.length ?? 0}</span>
                        <AiOutlineReload
                            onClick={reload}
                            className="hover:text-gray-200 cursor-pointer"
                            title="Ricarica lavorazioni"
                        />
                    </div>
                </div>
                {loading
                    ? <Loading label="Caricamento lavorazioni in corso"/>
                    : <div className="h-full overflow-y-auto">
                        <SubcontractorProcessesTable
                            subcontractorProcesses={filteredData ?? []}
                            onCreate={handleCreate}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    </div>
                }
            </div>
            <Modal open={editModalOpen} onOverlayClick={() => setEditModalOpen(false)}>
                <SubcontractorProcessesForm selected={selectedSubcontractorProcess} onSubmit={handleSubmit}/>
            </Modal>
        </>
    );
}

export default SubcontractorProcesses;
