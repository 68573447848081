import React, {useState} from 'react';
import Search from "./Search";
import {useReport} from "../../../utilities/contexts/report-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {useReactToPrint} from "react-to-print";
import {doGetSales} from "../../../logics/reports";
import {printPageStyle} from "../../../utilities/data/tools";
import {dateToFileName} from "../../../utilities/data/parser";
import Loading from "../../UI/Loading";
import SalesTable from "./SalesTable";
import SalesTotals from "./SalesTotals";
import HiddenPrintContainer from "../../UI/HiddenPrintContainer";
import SalesPrintable from "./SalesPrintable";

const SalesReport = () => {

    const {call} = useAsync();
    const {salesData, salesFormData, loadSales} = useReport();

    const [loading, setLoading] = useState(false);

    const printRef = React.useRef();

    const handleSubmit = async () => {
        setLoading(true);
        await call(
            () => doGetSales(salesFormData.date_from, salesFormData.date_to, salesFormData.client, salesFormData.nrlotto),
            (res) => {
                if(res.sold_leathers && res.grandtotals)
                loadSales(res.sold_leathers, res.grandtotals);
                else loadSales([], null);
            }
        );
        setLoading(false);
    }

    const handlePrint = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => printRef.current,
        documentTitle: `resoconto_vendite_${dateToFileName(salesFormData.date_from)}_${dateToFileName(salesFormData.date_to)}`
    });

    return (
        <div className="flex flex-col flex-1 " style={{minHeight: "100px"}}>
            <Search onSubmit={handleSubmit}/>

            <div className="flex flex-col flex-1 overflow-y-auto relative" style={{minHeight: "50px"}}>
            {loading?
                <Loading label="Caricamento" />
                :
                <SalesTable />
            }
            </div>

            {salesData &&
                <SalesTotals onClick={handlePrint} />
            }

            <HiddenPrintContainer>
                <div ref={printRef}>
                    <SalesPrintable />
                </div>
            </HiddenPrintContainer>
        </div>
    );
};

export default SalesReport;