import React, {useEffect, useState} from 'react';
import {useRegistry} from "../utilities/contexts/registry-context";
import useFilter from "../utilities/customHooks/filter";
import useAsync from "../utilities/customHooks/async-call";
import useToast from "../utilities/customHooks/toast";
import {
    doDeleteArticle,
    doGetArticle,
    doGetArticles,
    doPostArticle,
    doPutArticle
} from "../logics/registry";
import Layout from "../components/UI/Layout";
import BaseInput from "../components/UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import ArticlesTable from "../components/Articles/ArticlesTable";
import Loading from "../components/UI/Loading";
import Modal from "../components/UI/Modal";
import ArticleForm from "../components/Articles/ArticleForm";

const Articles = () => {

    const {articles, loadArticles} = useRegistry();
    const {call} = useAsync();
    const {toastMessage} = useToast();

    const [loading, setLoading] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selected, setSelectedArticle] = useState(null);

    const filterByName = (article) => {
        return article.nomeart.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(articles, filterByName);

    const reload = async () => {
        setLoading(true);
        setFilterText("");
        await call(
            () => doGetArticles(),
            (res) => {
                loadArticles(res);
            },
        );
        setLoading(false);
    }

    const handleCreate = () => {
        setSelectedArticle(null);
        setEditModalOpen(true);
    }

    const handleDelete = async (idart) => {
        if (!window.confirm('Eliminare articolo?')) {
            return;
        }
        await call(
            () => doDeleteArticle(idart),
            (res) => {
                if (res) {
                    toastMessage("Articolo eliminato");
                    reload();
                }
            }
        )
    }

    const handleEdit = async (idart) => {
        await call(
            () => doGetArticle(idart),
            (res) => {
                if (res && res[0]) {
                    console.log(res[0]);
                    setSelectedArticle(res[0]);
                }
            }
        )
        setEditModalOpen(true);
    }

    const handleSubmit = async (params) => {
        console.log(params);

        if (selected) {
            await call(
                () => doPutArticle(selected.idart, params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Articolo modificato");
                        reload();
                    }
                }
            );
        } else {
            await call(
                () => doPostArticle(params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Articolo creato");
                        reload();
                    }
                }
            );
        }

    }

    useEffect(() => {
        const load = async () => {
            await reload();
        }

        load();
    }, []);

    return (
        <Layout title="Articoli">
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className="mb-4">
                    <BaseInput placeholder={'Cerca articolo'}
                               className={'w-full mb-1'}
                               value={filterText}
                               onChange={(e) => setFilterText(e.target.value)}/>
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Articoli elencati:</span>
                        <span>{filteredData.length}</span>
                        <AiOutlineReload onClick={reload}
                                         className="hover:text-gray-200 cursor-pointer"
                                         title="Ricarica articoli"/>
                    </div>
                </div>
                {loading ?
                    <Loading label="Caricamento articoli in corso"/>
                    :
                    <div className="h-full overflow-y-auto">
                        <ArticlesTable
                            articles={filteredData}
                            onCreate={handleCreate}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    </div>
                }
            </div>
            <Modal open={editModalOpen} onOverlayClick={() => setEditModalOpen(false)}>
                <ArticleForm selected={selected} onSubmit={handleSubmit}/>
            </Modal>
        </Layout>
    );
};

export default Articles;
