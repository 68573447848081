import React from 'react';
import {formatInt, formatPrice, parseDate} from "../../../utilities/data/parser";
import {useReport} from "../../../utilities/contexts/report-context";
import TableHead from "../../Queries/General/Printable/TableHead";
import Th from "../../Queries/General/Printable/Th";
import Table from "../../Queries/General/Printable/Table";
import Td from "../../Queries/General/Printable/Td";

const SalesPrintable = () => {

    const {salesData, salesFormData} = useReport();

    return (
        <div>
            <div className="flex flex-col items-center border-2 border-black mb-2">
                <div>Resoconto vendite {salesFormData?.client?.name ? salesFormData.client.name : "di tutti i clienti"} dal {parseDate(salesFormData?.date_from)} al {parseDate(salesFormData?.date_to)}</div>
            </div>

            <Table className="w-full">
                <TableHead>
                    <Th>Nr Lotto</Th>
                    <Th>Provenienza</Th>
                    {/*<Th>Rif.</Th>*/}
                    <Th>Articolo - Colore</Th>
                    <Th>Cliente</Th>
                    <Th className="text-right">Resa</Th>
                    <Th className="text-right">Costo Mq</Th>
                    <Th className="text-right">Ve/Mq</Th>
                    <Th className="text-right">Diff. %</Th>
                    <Th className="text-right">N. Mq Vend.</Th>
                    <Th className="text-right">Tot. costo</Th>
                    <Th className="text-right">Tot. Vend.</Th>
                    <Th className="text-right">Margine</Th>
                    <Th className="text-right">Tot. Nr. Pz</Th>
                </TableHead>
                <tbody>
                {salesData.list && salesData.list.length > 0 &&
                    salesData.list.map((item, i) =>
                        <tr key={i}>
                            <Td>{item?.nrlotto}</Td>
                            <Td>{item?.buying_article}</Td>
                            {/*<Td className="border-l border-r"></td> /!*rif*!/*/}
                            <Td>{item?.sales_article}</Td>
                            <Td>{item?.client}</Td>
                            <Td className="text-right">{formatPrice(item?.performance)}</Td>
                            <Td className="text-right">{formatPrice(item?.costs_per_mq)}</Td>
                            <Td className="text-right">{formatPrice(item?.sold_per_mq)}</Td>
                            <Td className="text-right">{formatPrice(item?.diff_percentage)}</Td>
                            <Td className="text-right">{formatPrice(item?.sold_mq)}</Td>
                            <Td className="text-right">{formatPrice(item?.total_cost)}€</Td>
                            <Td className="text-right">{formatPrice(item?.total_profit)}€</Td>
                            <Td className="text-right">{formatPrice(item?.total_profit)}€</Td>
                            <Td className="text-right">{formatInt(item?.nrpz)}</Td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr className="font-bold">
                        <Td colSpan={9} className="text-right">Totali:</Td>
                        <Td className="text-right">{formatPrice(salesData?.totals?.sold_leathers?.costs)}€</Td>
                        <Td className="text-right">{formatPrice(salesData?.totals?.sold_leathers?.sales)}€</Td>
                        <Td className="text-right">{formatPrice(salesData?.totals?.sold_leathers?.profit)}€</Td>
                        <Td className="text-right">{formatInt(salesData?.totals?.sold_leathers?.nrpz)}</Td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};

export default SalesPrintable;
