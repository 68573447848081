import React, {useEffect, useRef, useState} from "react";
import Button from "../../UI/Button";
import BaseInput from "../../UI/BaseInput";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import FormTitle from "../../Lots/Movements/Creation/UI/FormTitle";
import {useParams} from "react-router-dom";
import SearchSelectArticle from "../../UI/Selects/SearchSelectArticle";
import SelectColors from "../../UI/Selects/SelectColors";
import SelectChoice from "../../UI/Selects/SelectChoice";

const ClientArticlesForm = ({selected, onSubmit}) => {
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const formRef = useRef();
    const routeParams = useParams();

    const handleSelectProcess = (selectedArticle) => {
        setSelectedArticle(selectedArticle);
    }

    const handleSelectColor = (selectedColor) => {
        setSelectedColor(selectedColor);
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        let data = formRef.current.data.value ? formRef.current.data.value : new Date().toISOString().slice(0, 10);
        let prezzo1 = formRef.current.prezzo1.value ?? 0;
        let prezzo2 = formRef.current.prezzo2.value ? formRef.current.prezzo2.value : 0;
        let prezzo3 = formRef.current.prezzo3.value ? formRef.current.prezzo3.value : 0;
        let idscelta1 = formRef.current.choise1.value ? formRef.current.choise1.value : 0;
        let idscelta2 = formRef.current.choise2.value ? formRef.current.choise2.value : 0;
        let idscelta3 = formRef.current.choise3.value ? formRef.current.choise3.value : 0;
        let lirepiede1 = formRef.current.lirepiede1.value;
        let lirepiede2 = formRef.current.lirepiede2.value;
        let lirepiede3 = formRef.current.lirepiede3.value;
        let note = formRef.current.note.value;
        const idcl = routeParams?.idcl;
        const idart = selectedArticle?.id;
        const idcol = selectedColor?.id;

        if (selected) {
            const params = {
                data,
                prezzo1,
                prezzo2,
                prezzo3,
                idscelta1,
                idscelta2,
                idscelta3,
                lirepiede1,
                lirepiede2,
                lirepiede3,
                note,
                idcl,
                idart,
                idcol
            }
            onSubmit && onSubmit(params);
        } else {
            let params = new URLSearchParams();
            params.append('data', data);
            params.append('note', note);
            params.append('prezzo1', prezzo1);
            params.append('prezzo2', prezzo2);
            params.append('prezzo3', prezzo3);
            params.append('choise1', idscelta1);
            params.append('choise2', idscelta2);
            params.append('lirepiede1', lirepiede1);
            params.append('lirepiede2', lirepiede2);
            params.append('lirepiede3', lirepiede3);
            params.append("idcl", idcl)
            params.append("idart", idart)
            params.append("idcol", idcol)
            onSubmit && onSubmit(params);
        }
    }

    useEffect(() => {
        if (selected) {
            if (selected?.article) {
                const article = {id: selected?.article.idart, name: selected.article.nomeart}
                setSelectedArticle(article)
            }
            if (selected?.color) {
                const color = {id: selected?.color.idcol, name: selected.color.nomecol}
                setSelectedColor(color)
            }
            if (selected.lirepiede1) formRef.current.lirepiede1.value = selected.lirepiede1;
            if (selected.lirepiede2) formRef.current.lirepiede2.value = selected.lirepiede2;
            if (selected.lirepiede3) formRef.current.lirepiede3.value = selected.lirepiede3;
            if (selected.prezzo1) formRef.current.prezzo1.value = selected.prezzo1;
            if (selected.prezzo2) formRef.current.prezzo2.value = selected.prezzo2;
            if (selected.prezzo3) formRef.current.prezzo3.value = selected.prezzo3;
            if (selected.data) formRef.current.data.value = selected.data ? selected.data : new Date().toISOString().slice(0, 10);
            if (selected.note) formRef.current.note.value = selected.note;
            if (selected.choise1) formRef.current.choise1.value = selected.choise1.idscelta ? selected.choise1.idscelta : 0;
            if (selected.choise2) formRef.current.choise2.value = selected.choise2.idscelta ? selected.choise2.idscelta : 0;
            if (selected.choise3) formRef.current.choise3.value = selected.choise3.idscelta ? selected.choise3.idscelta : 0;
        }
    }, [selected]);

    return (
        <>
            <FormTitle>{selected ? "Modifica" : "Crea"} Articolo</FormTitle>
            <form onSubmit={handleSubmit}
                  className="flex flex-col gap-2"
                  ref={formRef}
                  style={{minWidth: "300px"}}>
                {!selected &&
                    <>
                        <VerticalLabelledField label="Articoli">
                            <SearchSelectArticle
                                value={selectedArticle}
                                onChange={handleSelectProcess}
                                disabled={!!selected}/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label="Colori">
                            <SelectColors
                                value={selectedColor}
                                onChange={handleSelectColor}
                                disabled={!!selected}/>
                        </VerticalLabelledField>
                    </>
                }
                <VerticalLabelledField label="Data">
                    <BaseInput
                        name="data"
                        type="date"
                        placeholder="wut"
                    />
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo nr. 1">
                    <div className="flex flex-row gap-2">
                        <BaseInput placeholder="Prezzo nr. 1" name="prezzo1"/>
                        <SelectChoice name="choise1"/>
                        <BaseInput placeholder="Lire/pq 1" name="lirepiede1"/>
                    </div>
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo nr. 2">
                    <div className="flex flex-row gap-2">
                        <BaseInput placeholder="Prezzo nr. 2" name="prezzo2"/>
                        <SelectChoice name="choise2"/>
                        <BaseInput placeholder="Lire/pq 2" name="lirepiede2"/>
                    </div>
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo nr. 3">
                    <div className="flex flex-row gap-2">
                        <BaseInput placeholder="Prezzo nr. 3" name="prezzo3"/>
                        <SelectChoice name="choise3"/>
                        <BaseInput placeholder="Lire/pq 3" name="lirepiede3"/>
                    </div>
                </VerticalLabelledField>
                <VerticalLabelledField label="Note">
                    <BaseInput placeholder="Note" name="note"/>
                </VerticalLabelledField>
                <Button>{selected ? "Modifica" : "Crea"}</Button>
            </form>
        </>
    );
};

export default ClientArticlesForm;
