import {get7daysAgo} from "../data/tools";
import types from "../types/types";

export const defaultState = {
    salesData: {
        list: [],
        totals: null,
    },
    salesFormData: {
        date_from: get7daysAgo(),
        date_to: new Date().toISOString().split('T')[0],
        client: null,
        nrlotto: "",
    },
    costsData: {
        list: [],
        totals: null,
    },
    costsFormData: {
        date_from: get7daysAgo(),
        date_to: new Date().toISOString().split('T')[0],
        client: null,
        nrlotto: "",
    },
    movementListData: {
        list: [],
        totals: null,
    },
    movementListFormData: {
        date_from: get7daysAgo(),
        date_to: new Date().toISOString().split('T')[0],
        nrlotto: "",
    },
    journalData: {
        list: [],
        totals: null,
    },
    journalFormData: {
        date_from: get7daysAgo(),
        date_to: new Date().toISOString().split('T')[0],
        nrlotto: "",
        totals: false,
    },
    ledgerData: {
        list: [],
        totals: null,
    },
    ledgerFormData: {
        date_from: get7daysAgo(),
        date_to: new Date().toISOString().split('T')[0],
        nrlotto: "",
    }
}

function reportReducer(state, action) {
    switch(action.type) {
        case types.REPORTS_LOAD_SALES: {
            return {
                ...state,
                salesData: {
                    list: action.list,
                    totals: action.totals,
                }
            }
        }
        case types.REPORTS_SALES_SET_FORM: {
            return {
                ...state,
                salesFormData: {
                    date_from: action.date_from,
                    date_to: action.date_to,
                    client: action.client,
                    nrlotto: action.nrlotto,
                }
            }
        }
        case types.REPORTS_LOAD_COSTS: {
            return {
                ...state,
                costsData: {
                    list: action.list,
                    totals: action.totals,
                }
            }
        }
        case types.REPORTS_COSTS_SET_FORM: {
            return {
                ...state,
                costsFormData: {
                    date_from: action.date_from,
                    date_to: action.date_to,
                    client: action.client,
                    nrlotto: action.nrlotto,
                }
            }
        }
        case types.REPORTS_LOAD_MOVEMENTS: {
            return {
                ...state,
                movementListData: {
                    list: action.list,
                    totals: action.totals
                }
            }
        }
        case types.REPORTS_MOVEMENTS_SET_FORM: {
            return {
                ...state,
                movementListFormData: {
                    date_from: action.date_from,
                    date_to: action.date_to,
                    nrlotto: action.nrlotto
                }
            }
        }
        case types.REPORTS_LOAD_JOURNAL: {
            return {
                ...state,
                journalData: {
                    list: action.list,
                    totals: action.totals,
                }
            }
        }
        case types.REPORTS_JOURNAL_SET_FORM: {
            return {
                ...state,
                journalFormData: {
                    date_from: action.date_from,
                    date_to: action.date_to,
                    nrlotto: action.nrlotto,
                    totals: action.totals,
                }
            }
        }
        case types.REPORTS_LOAD_LEDGER: {
            return {
                ...state,
                ledgerData: {
                    list: action.list,
                    totals: action.totals,
                }
            }
        }
        case types.REPORTS_LEDGER_SET_FORM: {
            return {
                ...state,
                ledgerFormData: {
                    date_from: action.date_from,
                    date_to: action.date_to,
                    nrlotto: action.nrlotto
                }
            }
        }
        default:
            throw new Error("Unhandled action type " + action.type);
    }
}

export default reportReducer;
