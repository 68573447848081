import React from 'react';
import ColorRow from "./ColorRow";
import {BiPlus} from "react-icons/bi";

const ColorsTable = ({colors, onCreate, onEdit, onDelete, ...props}) => {
    const handleCreate = () => {
        onCreate && onCreate();
    }
    const handleEdit = (idcol) => {
        onEdit && onEdit(idcol)
    }
    const handleDelete = (idcol) => {
        onDelete && onDelete(idcol)
    }

    return (
        <table className="w-full text-left">
            <thead>
            <tr className="border-b border-gray-600">
                <th className="pl-2 ">Codice</th>
                <th className="pl-2 ">Nome</th>
                <th className="pl-2 ">Tipologia</th>
                <th>
                    <span className="flex flex-row justify-center items-center w-full">
                        <BiPlus
                            onClick={handleCreate}
                            title={'Nuovo colore'}
                            className="cursor-pointer hover:text-green-400"/>
                    </span>
                </th>
            </tr>
            </thead>
            <tbody className=" w-full ">
            {colors && colors.length > 0 &&
                colors.map((color, i) =>
                    <ColorRow
                        color={color}
                        key={i}
                        onClick={handleEdit}
                        onDelete={handleDelete}/>
                )
            }
            </tbody>
        </table>
    );
};

export default ColorsTable;
