import React from 'react';
import Button from "../Button";
import Row from "../Structure/Row";

const RowForm = ({submitLabel = "Ricerca", ...props}) => {
    return (
        <form className="flex flex-row items-center justify-between w-full" {...props}>
            <Row className="gap-2">
                {props.children}
            </Row>
            <Button>{submitLabel}</Button>
        </form>
    );
};

export default RowForm;
