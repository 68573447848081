import React from 'react';
import {useLot} from "../../../utilities/contexts/lot-context";
import {formatInt, formatPrice, priceFormat} from "../../../utilities/data/parser";
import HeaderSection from "../LotHeader/UI/HeaderSection";

const BalanceRecap = () => {
    const {balance} = useLot();

    return (
        <HeaderSection label="DATI DI VENDITA" className="w-full text-xs" collapsable={true} inverted={true} defaultOpen={false}>
            <div className="flex flex-col md:flex-row gap-1">
                <table className="flex-1 text-right">
                    <thead>
                    <tr className="border-b border-gray-400">
                        <th className="text-left">PELLI</th>
                        <th>Nr</th>
                        <th>Performance</th>
                        <th>mq</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="text-left">acquistate</td>
                        <td>{formatInt(balance.leathers.purchased.amount)}</td>
                        <td>{formatPrice(balance.leathers.purchased.performance)}</td>
                        <td>{formatPrice(balance.leathers.purchased.mt)}</td>
                    </tr>
                    <tr>
                        <td className="text-left">vendute</td>
                        <td>{formatInt(balance.leathers.sold.amount)}</td>
                        <td>{formatPrice(balance.leathers.sold.performance)}</td>
                        <td>{formatPrice(balance.leathers.sold.mt)}</td>
                    </tr>
                    <tr>
                        <td className="text-left">in magazzino</td>
                        <td>{formatInt(balance.leathers.in_house)}</td>
                    </tr>
                    <tr>
                        <td className="text-left">resi</td>
                        <td>{formatInt(balance.leathers.returned)}</td>
                    </tr>
                    <tr>
                        <td className="text-left">Differenza</td>
                        <td>{formatInt(balance.leathers.difference.amount)}</td>
                        <td className={"font-bold"}>Diff.%</td>
                        <td>{formatInt(balance.leathers.difference.performance)}%</td>
                    </tr>
                    <tr>
                    </tr>
                    </tbody>
                </table>
                <table className="flex-1 text-right ">
                    <thead className="border-b border-gray-400">
                    <tr>
                        <th></th>
                        <th className="text-right">COSTI</th>
                        <th className="text-right">RICAVI</th>
                        <th className="text-right">PROFITTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="text-left">
                            AL PIEDE
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_foot.cost) + "€"}
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_foot.revenue) + "€"}
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_foot.margin) + "€"}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">
                            ALLA PELLE
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_leather.cost) + "€"}
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_leather.revenue) + "€"}
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_leather.margin) + "€"}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">
                            AL METRO
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_mt.cost) + "€"}
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_mt.revenue) + "€"}
                        </td>
                        <td>
                            {priceFormat(balance.balance.per_mt.margin) + "€"}
                        </td>
                    </tr>
                    </tbody>
                    <tfoot className="border-t">
                    <tr>
                        <td className="font-bold text-left">TOTALE</td>

                        <td className="text-right">{priceFormat(balance.balance.totals.cost) + "€"}</td>
                        <td className="text-right">{priceFormat(balance.balance.totals.revenue) + "€"}</td>
                        <td className="text-right">{priceFormat(balance.balance.totals.margin) + "€"}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </HeaderSection>
    );
};

export default BalanceRecap;
