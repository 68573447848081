import {FaTrashAlt} from "react-icons/fa";
import TableRow from "../../UI/table/TableRow";


const SubcontractorProcessRow = ({subcontractorProcess, onClick, onDelete}) => {
    const handleRowClick = async () => {
        onClick && onClick(subcontractorProcess.IdLav);
    }

    const handleDelete = async (event) => {
        event.stopPropagation();
        onDelete && onDelete(subcontractorProcess.IdTerzLav)
    }

    return (
        <TableRow onClick={handleRowClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">{subcontractorProcess.Nomelav}</td>
            <td className="pl-2 ">
                {subcontractorProcess.rel.data &&
                !isNaN(Date.parse(subcontractorProcess.rel.data))
                    ? new Date(subcontractorProcess.rel.data).toLocaleDateString('it-IT')
                    : ''
                }
            </td>
            <td className="pl-2 ">{subcontractorProcess.rel.prezzo1}</td>
            <td className="pl-2 ">{subcontractorProcess.rel?.um1?.um}</td>
            <td className="pl-2 ">{subcontractorProcess.rel.prezzo2}</td>
            <td className="pl-2 ">{subcontractorProcess.rel?.um2?.um}</td>
            <td className="pl-2 ">{subcontractorProcess.rel.prezzo3}</td>
            <td className="pl-2 ">{subcontractorProcess.rel?.um3?.um}</td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt
                        title="Cancella Lavorazione"
                        onClick={handleDelete}
                        className="cursor-pointer hover:text-red-500"
                    />
                </div>
            </td>
        </TableRow>
    )
};

export default SubcontractorProcessRow;
