import React, {useEffect, useRef} from 'react';
import Button from "../UI/Button";
import BaseInput from "../UI/BaseInput";
import Select from "../UI/Select";
import Textarea from "../UI/Textarea";
import VerticalLabelledField from "../UI/Fields/VerticalLabelledField";
import FormTitle from "../Lots/Movements/Creation/UI/FormTitle";

const ProcessesForm = ({selected, onSubmit}) => {
    const formRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        let nomelav = formRef.current.nomelav.value;
        let descrizionelav = formRef.current.descrizionelav.value;

        if (selected) {
            let params = {
                nomelav,
                descrizionelav,
            }
            onSubmit && onSubmit(params);
        } else {
            let params = new URLSearchParams();
            params.append('nomelav', nomelav);
            params.append('descrizionelav', descrizionelav);
            onSubmit && onSubmit(params);
        }

    }

    useEffect(() => {
        if (selected) {
            if (selected?.nomelav) formRef.current.nomelav.value = selected.nomelav;
            if (selected?.descrizionelav) formRef.current.descrizionelav.value = selected.descrizionelav;
        }
    }, [selected]);

    return (
        <div>
            <FormTitle>{selected ? "Modifica" : "Crea"} lavorazione</FormTitle>
            <form onSubmit={handleSubmit}
                  className="flex flex-col gap-2"
                  ref={formRef}
                  style={{minWidth: "300px"}}>
                <VerticalLabelledField label="Nome">
                    <BaseInput placeholder="Nome" name="nomelav"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Descrizione">
                    <BaseInput placeholder="Descrizione" name="descrizionelav"/>
                </VerticalLabelledField>
                <Button>{selected ? "Modifica" : "Crea"}</Button>
            </form>
        </div>
    );
};

export default ProcessesForm;
