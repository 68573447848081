import React, {useEffect, useRef} from 'react';
import FormTitle from "../Lots/Movements/Creation/UI/FormTitle";
import VerticalLabelledField from "../UI/Fields/VerticalLabelledField";
import BaseInput from "../UI/BaseInput";
import Textarea from "../UI/Textarea";
import Select from "../UI/Select";
import Button from "../UI/Button";

const ColorForm = ({color, onSubmit}) => {

    const formRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        const codcol = formRef.current.codcol.value;
        const nomecol = formRef.current.nomecol.value;
        const descrizione = formRef.current.descrizione.value;
        const idtipocol = formRef.current.idtipocol.value;

        if (color) {
            const params = {
                codcol,
                nomecol,
                descrizione,
                idtipocol,
            }
            onSubmit && onSubmit(params);

        } else {
            let params = new URLSearchParams();
            params.append('codcol', codcol);
            params.append('nomecol', nomecol);
            params.append('descrizione', descrizione);
            params.append('idtipocol', idtipocol);
            onSubmit && onSubmit(params);
        }
    }

    useEffect(() => {
        if (color) {
            if (color.codcol) formRef.current.codcol.value = color.codcol;
            if (color.nomecol) formRef.current.nomecol.value = color.nomecol;
            if (color.descrizione) formRef.current.descrizione.value = color.descrizione;
            if (color.tipcol.idtipocol) formRef.current.idtipocol.value = color.tipcol.idtipocol;
        }
    }, [color])

    return (
        <div>
            <FormTitle>{color ? "Modifica" : "Crea"} colore</FormTitle>
            <form
                ref={formRef}
                className="flex flex-col gap-2"
                onSubmit={handleSubmit}
                style={{minWidth: "300px"}}
            >
                <VerticalLabelledField label="Codice">
                    <BaseInput name="codcol" placeholder="Codice"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Nome">
                    <BaseInput name="nomecol" placeholder="Nome"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Descrizione">
                    <Textarea placeholder="Descrizione" name="descrizione"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Tipologia">
                    <Select name="idtipocol">
                        <option value="2">acquisto</option>
                        <option value="3">c/to lavoro</option>
                        <option value="1">vendita</option>
                    </Select>
                </VerticalLabelledField>
                <Button>{color ? "Modifica" : "Crea"}</Button>
            </form>
        </div>
    );
};

export default ColorForm;
