import React from 'react';
import CustomTabLink from "../UI/CustomTabLink";

const Navigation = () => {
    return (
        <div className="flex flex-row items-center gap-1 mt-2 px-2">
            <CustomTabLink to="in_sede">Pelli in sede</CustomTabLink>
            <CustomTabLink to="terzisti">Pelli dai terzisti</CustomTabLink>
            {/*<CustomTabLink to="riepilogo">Riepilogo lotto</CustomTabLink>*/}
            <CustomTabLink to="vendite_settimanali">Vendite per intervallo date</CustomTabLink>
            <CustomTabLink to="vendite_cliente">Vendite per cliente</CustomTabLink>
            <CustomTabLink to="documenti_terzista">Documenti terzista</CustomTabLink>
        </div>
    );
};

export default Navigation;
