import React from 'react';
import Modal from "../../../UI/Modal";
import {useLot} from "../../../../utilities/contexts/lot-context";
import BaseInput from "../../../UI/BaseInput";
import Button from "../../../UI/Button";
import useAsync from "../../../../utilities/customHooks/async-call";
import {doGetLotMovements, doPostLotFirstMovement} from "../../../../logics/lots";
import FirstMovementDetails from "../FirstMovementDetails";
import useToast from "../../../../utilities/customHooks/toast";
import {useParams} from "react-router-dom";

const FirstMovementCreation = ({open, ...props}) => {
    const {currentLot, openModal, loadMovements} = useLot();
    const {toastError, toastConfirm} = useToast();
    const {call} = useAsync();
    const params = useParams();

    const formRef = React.createRef();

    const onOverlayClick = () => {
        openModal("createMovement", false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let datareg = formRef.current.datareg.value;
        let notereg = formRef.current.notereg.value;

        const fields = {
            datareg,
            notereg
        }

        await call(
            () => doPostLotFirstMovement(currentLot.nrlotto, fields),
            async () => {
                //load first movement (tree)
                await call(
                    () => doGetLotMovements(params.nr),
                    (res) => {
                        if (res) {
                            loadMovements(res[0]);
                        } else {
                            loadMovements(null);
                        }
                    },
                    (err) => {
                        console.warn(err);
                        loadMovements(null);
                    }
                );

                //close form
                openModal("createMovement", false)
                //Show success message
                toastConfirm("Primo movimento creato con successo")
            },
            (err) => {
                toastError("Si è verificato un errore nella creazione del movimento.")
                console.log(err);
            }
        )
    }

    return (
        <Modal open={open} onOverlayClick={onOverlayClick}>
            <div>Aggiungi il primo movimento del lotto {currentLot.nrlotto}</div>

            <FirstMovementDetails/>

            <form onSubmit={handleSubmit} ref={formRef}>
                <BaseInput label="Data registrazione" type="date" name="datareg" required/>
                <div className="mt-2">
                    <div className="p-0 m-0 text-xs uppercase text-gray-600">Note registrazione</div>
                    <textarea name="notereg" className="w-full p-1 bg-black bg-opacity-5 rounded shadow-inner "/>
                </div>
                <Button type="submit">Conferma</Button>
            </form>
        </Modal>
    );
};

export default FirstMovementCreation;
