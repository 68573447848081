import React from 'react';
import TableRow from "../UI/table/TableRow";
import {FaTrashAlt} from "react-icons/fa";

const ArticleRow = ({article, onClick, onDelete}) => {

    const handleClick = async () => {
        onClick && onClick(article.idart);
    }

    const handleDelete = async (event) => {
        event.stopPropagation();
        onDelete && onDelete(article.idart)
    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">{article.codart}</td>
            <td className="pl-2 ">{article.nomeart}</td>
            <td className="pl-2 ">{article.tipart.nometipoart}</td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt
                        title="Cancella Articolo"
                        onClick={handleDelete}
                        className="cursor-pointer hover:text-red-500"
                    />
                </div>
            </td>
        </TableRow>
    )
};

export default ArticleRow;
