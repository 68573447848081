import React, {useEffect, useRef, useState, useCallback} from 'react';
import ModalTitleWithCloseButton from "../Creation/UI/ModalTitleWithCloseButton";
import {useLot} from "../../../../utilities/contexts/lot-context";
import VerticalLabelledField from "../../../UI/Fields/VerticalLabelledField";
import DocumentInfo from "../../../UI/DataDisplay/DocumentInfo";
import {priceFormat} from "../../../../utilities/data/parser";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import {doGetMovement} from "../../../../logics/lots";
import useAsync from "../../../../utilities/customHooks/async-call";
import treeData from "../../../../utilities/data/tree";
import {useTree} from "../../../../utilities/contexts/tree-context";

const SimpleView = () => {

    const {currentLot, currentMovement, setCurrentMovement} = useLot();
    const {tree} = useTree();

    const {call} = useAsync();

    const [loading, setLoading] = useState(false);

    const ref = useRef();

    const currentMovementRef = useRef(currentMovement);
    const _setCurrentMovement = data => {
        currentMovementRef.current = data;
        setCurrentMovement(data);
    }

    const loadMovement = async (node, idnodo) => {
        await call(
            () => doGetMovement(currentLot.nrlotto, idnodo),
            (res) => {
                if (res)
                    _setCurrentMovement({...node, ...res});
            }
        )
    }

    const next = async () => {
        let node = treeData.next(tree, currentMovementRef.current.idnodo);
        if(node) {
            await loadMovement(node, node.idnodo);
        }
    };

    const prev = async () => {
        let node = treeData.prev(tree, currentMovementRef.current.idnodo);
        if(node)
            await loadMovement(node, node.idnodo);
    };

    const handleKeyPress = useCallback(async (e) => {
        if (!ref.current || ref.current.contains(e.target)) {
            return;
        }
        if(!loading) {
            let pressedKey = e.key;
            await setLoading(true);
            if(pressedKey === "ArrowLeft" || pressedKey === "ArrowUp")
                await prev();
            if(pressedKey === "ArrowRight" || pressedKey === "ArrowDown")
                await next();
            await setLoading(false);
        }
    }, [next, prev, currentMovement])

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return function cleanup() {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    return (
        <div className="flex flex-col gap-1" ref={ref}>
            <ModalTitleWithCloseButton title="Visualizza dettagli"
                                       modal="view"/>
            <div className="flex flex-row items-start gap-2 justify-between">
                <VerticalLabelledField label="Nr. Lotto">
                    <span className="font-bold">{currentLot.nrlotto}</span>
                </VerticalLabelledField>
                <VerticalLabelledField label="Nr. Pezzi">
                    {currentMovement.nrpz}
                </VerticalLabelledField>
                <VerticalLabelledField label="Nr. Pezzi disponibili">
                    {currentMovement.available_leathers ?? 0}
                </VerticalLabelledField>
            </div>
            <div className="border-b border-gray-400"/>
            <div className="flex flex-row items-start gap-2 justify-between">
                <VerticalLabelledField label="Documento">
                    <DocumentInfo document={currentMovement.document}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Azienda">
                    {currentMovement.document?.company ??
                    <span className="text-gray-400 italic text-sm">Non rilevata</span>}
                </VerticalLabelledField>
            </div>
            <VerticalLabelledField label="Articolo">{currentMovement?.article?.name}</VerticalLabelledField>
            <VerticalLabelledField label="Colore">{currentMovement?.color?.name}</VerticalLabelledField>
            <VerticalLabelledField label="Lavorazione">{currentMovement?.processing?.name}</VerticalLabelledField>
            <VerticalLabelledField label="Note movimento">{currentMovement?.notemov ??
            <span className="text-gray-400 italic text-sm">Nessuna nota presente</span>}</VerticalLabelledField>
            <VerticalLabelledField label="Quantità movimento">
                ?
            </VerticalLabelledField>
            <div className="border-b border-gray-400"/>
            <div className="flex flex-row items-start gap-2 justify-between">
                <VerticalLabelledField label="Costo totale lavorazione">
                    {priceFormat(currentMovement?.costs?.movement)}€
                </VerticalLabelledField>
                <VerticalLabelledField label="Costo al pezzo">
                    {priceFormat(currentMovement?.costs?.per_leather)}€
                </VerticalLabelledField>
                <VerticalLabelledField label="Costo parziale al pezzo">
                    {priceFormat(currentMovement?.costs?.progressive_per_leather)}€
                </VerticalLabelledField>
            </div>
            <VerticalLabelledField label="Pelli inviate a">
                {currentMovement?.subcontractor?.name ?? currentMovement?.client?.name}

            </VerticalLabelledField>

            <div className="mt-2">

                <div className="flex flex-row justify-between text-gray-600">
                    <FaArrowLeft onClick={prev}
                                 title="Mostra movimento precendente"
                                 className="cursor-pointer text-xl hover:text-secondary"/>
                    <div className="italic">Utilizza le frecce (anche da tastiera) per scorrere tra i movimenti</div>
                    <FaArrowRight onClick={next}
                                  title="Mostra movimento successivo"
                                  className="cursor-pointer text-xl hover:text-secondary"/>
                </div>
            </div>
        </div>
    );
};

export default SimpleView;