import React from 'react';
import {NavLink} from "react-router-dom";

const CustomTabLink = ({to, ...props}) => {
    const activeLinkStyle = "bg-blue-400 font-bold text-white";

    return (
        <NavLink to={to} className={({ isActive }) =>
            `${isActive ? activeLinkStyle : "bg-blue-200 hover:bg-blue-300"} cursor-pointer text-xs rounded-t-lg`
        }>
            <div className="p-1">
                {props.children}
            </div>
        </NavLink>
    );
};

export default CustomTabLink;