import Layout from "../components/UI/Layout";
import {Outlet} from "react-router-dom";

const Clients = () => {
    return (
        <Layout title="Clienti">
            <Outlet/>
        </Layout>
    )
}

export default Clients;
