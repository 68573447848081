import React from 'react';
import Section from "../../UI/Structure/Section";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import BaseInput from "../../UI/BaseInput";
import RowForm from "../../UI/Form/RowForm";
import {useReport} from "../../../utilities/contexts/report-context";

const Search = () => {

    const {journalFormData, setJournalForm} = useReport();

    const handleStartChange = (e) => {
        setJournalForm(e.target.value, journalFormData.date_to, journalFormData.nrlotto, journalFormData.totals);
    }
    const handleEndChange = (e) => {
        setJournalForm(journalFormData.date_from, e.target.value, journalFormData.nrlotto, journalFormData.totals);
    }
    const handleLotChange = (e) => {
        setJournalForm(journalFormData.date_from, journalFormData.date_to, e.target.value, journalFormData.totals);
    }
    const handleTotalChange = (e) => {
        setJournalForm(journalFormData.date_from, journalFormData.date_to, journalFormData.nrlotto, e.target.checked);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <Section collapsable={true} label="Giornale movimenti magazzino">
            <RowForm onSubmit={handleSubmit}>
                <VerticalLabelledField label="Dal">
                    <BaseInput type="date" value={journalFormData.date_from} onChange={handleStartChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Al">
                    <BaseInput type="date" value={journalFormData.date_to} onChange={handleEndChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Nr. lotto">
                    <BaseInput placeholder="Numero lotto" value={journalFormData.nrlotto} onChange={handleLotChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Vis. Totali">
                    <BaseInput type="checkbox" checked={journalFormData.totals} onChange={handleTotalChange}/>
                </VerticalLabelledField>
            </RowForm>
        </Section>
    );
};

export default Search;