import React from 'react';
import Search from "./Search";

const Ledger = () => {
    return (
        <div className="flex flex-col flex-1 " style={{minHeight: "100px"}}>
            <Search />
        </div>
    );
};

export default Ledger;