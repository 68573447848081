import React from 'react';
import CustomTabLink from "../UI/CustomTabLink";

const Navigation = () => {
    return (
        <div className="flex flex-row items-end gap-1 mt-2 px-2">
            <CustomTabLink to="vendite">Resoconto vendite</CustomTabLink>
            <CustomTabLink to="costi">Resoconto costi lav.</CustomTabLink>
            <CustomTabLink to="movimenti">Elenco completo movimenti</CustomTabLink>
            <CustomTabLink to="giornale">Giornale mov.mag.</CustomTabLink>
            <CustomTabLink to="partitari">Partitari</CustomTabLink>
        </div>
    );
};

export default Navigation;