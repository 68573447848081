import {BiPlus} from "react-icons/bi";
import SubcontractorProcessRow from "./SubcontractorProcessRow";

const SubcontractorProcessesTable = ({subcontractorProcesses, onCreate, onEdit, onDelete}) => {

    const handleCreate = () => {
        onCreate && onCreate();
    }

    const handleEdit = (IdLav) => {
        onEdit && onEdit(IdLav);
    }
    const handleDelete = (IdTerzLav) => {
        onDelete && onDelete(IdTerzLav);
    }

    return (
        <table className="w-full text-left">
            <thead>
            <tr className="border-b border-gray-600">
                <th className="pl-2 ">Lavorazione</th>
                <th className="pl-2 ">Data</th>
                <th className="pl-2 ">Prezzo nr.1</th>
                <th className="pl-2 ">U.m nr.1</th>
                <th className="pl-2 ">Prezzo nr.2</th>
                <th className="pl-2 ">U.m nr.2</th>
                <th className="pl-2 ">Prezzo nr.3</th>
                <th className="pl-2 ">U.m nr.3</th>
                <th>
                    <span className="flex flex-row justify-center items-center w-full">
                        <BiPlus
                            onClick={handleCreate}
                            title={'Associa lavorazione'}
                            className="cursor-pointer hover:text-green-400"
                        />
                    </span>
                </th>
            </tr>
            </thead>
            <tbody className=" w-full ">
            {subcontractorProcesses && subcontractorProcesses.length > 0 &&
                subcontractorProcesses.map((process, i) =>
                    <SubcontractorProcessRow subcontractorProcess={process} key={i} onClick={handleEdit}
                                             onDelete={handleDelete}/>
                )
            }
            </tbody>
        </table>
    );
};

export default SubcontractorProcessesTable;
