import React from 'react';
import {BiPlus} from "react-icons/bi";
import SubcontractorRow from "./SubcontractorRow";
import {useRegistry} from "../../utilities/contexts/registry-context";

const SubcontractorsTable = ({subcontractors, onDelete, ...props}) => {

    const {setCreatePanelOpen} = useRegistry();

    const handleDelete = (idterz) => {
        onDelete && onDelete(idterz);
    }

    const handleCreate = () => {
        setCreatePanelOpen(true);
    }

    return (
        <table className="w-full max-h-screen text-left">
            <thead>
            <tr className="border-b border-gray-600">
                <th className="pl-2 ">Ragione sociale</th>
                <th className="pl-2 "> Contatto </th>
                <th>
                    <div className="flex flex-row justify-center items-center w-full">
                        <BiPlus onClick={handleCreate}
                                title={'Nuovo terzista'}
                                className="cursor-pointer hover:text-green-400"/>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody className="max-h-screen-50 overflow-y-auto w-full ">
            {subcontractors && subcontractors.length > 0 &&
                subcontractors.map((subcontractor, i) =>
                    <SubcontractorRow
                        subcontractor={subcontractor}
                        key={i}
                        onDelete={handleDelete}/>
                )
            }
            </tbody>
        </table>
    )
}

export default SubcontractorsTable;
