import React, {useState} from 'react';
import RowAligned from "../../../UI/Structure/RowAligned";
import Toggle from "../../../UI/Toggle";

const HeaderSection = (
    {
        label,
        className = "",
        color = "secondary",
        collapsable = false,
        inverted = false,
        defaultOpen = true,
        ...props
    }) => {

    const [open, setOpen] = useState(defaultOpen);

    return (
        <div className={className}>
            <RowAligned
                className={` justify-between  bg-${color} bg-opacity-30 border-${color} px-1 ${!open && ""} ${collapsable && "cursor-pointer"}`}
                onClick={() => collapsable && setOpen(!open)}>
                <div className={`uppercase text-black  font-medium text-sm leading-2`}>{label}</div>
                {collapsable &&
                <Toggle open={open} inverted={inverted} className="text-{color}"/>
                }

            </RowAligned>
            {open &&
            <div className={`bg-${color} bg-opacity-10 border-${color} p-1`}>
                {props.children}
            </div>
            }

        </div>
    );
};

export default HeaderSection;
