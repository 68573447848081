import React from 'react';
import {useReport} from "../../../utilities/contexts/report-context";
import EmptyQuery from "../../Queries/General/EmptyQuery";
import TableHeader from "../../Queries/General/TableHeader";
import Table from "../../Queries/General/Table";
import SalesRow from "./SalesRow";

const SalesTable = () => {

    const {salesData, salesFormData} = useReport();

    return (
        <div>
            {salesData.list && salesData.list.length > 0 ?
                <Table>
                    <TableHeader>
                        <th>Nr Lotto</th>
                        <th>Provenienza</th>
                        {/*<th>Rif.</th>*/}
                        <th>Articolo - Colore</th>
                        <th>Cliente</th>
                        <th className="text-right pr-1">Resa</th>
                        <th className="text-right pr-1">Costo Mq</th>
                        <th className="text-right pr-1">Ve/Mq</th>
                        <th className="text-right pr-1">Diff. %</th>
                        <th className="text-right pr-1">N. Mq Vend.</th>
                        <th className="text-right pr-1">Tot. costo</th>
                        <th className="text-right pr-1">Tot. Vend.</th>
                        <th className="text-right pr-1">Margine</th>
                        <th className="text-right pr-1">Tot. Nr. Pz</th>
                    </TableHeader>
                    <tbody>
                    {salesData.list.map((item, i) =>
                        <SalesRow row={item} key={i} />
                    )}
                    </tbody>
                </Table>

                :
                <EmptyQuery />
            }
        </div>
    );
};

export default SalesTable;