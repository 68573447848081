import React from 'react';
import {useReport} from "../../../utilities/contexts/report-context";
import Table from "../../Queries/General/Table";
import TableHeader from "../../Queries/General/TableHeader";
import Th from "../../Queries/General/Printable/Th";
import CostsRow from "./CostsRow";
import EmptyQuery from "../../Queries/General/EmptyQuery";

const CostsTable = () => {

    const {costsData} = useReport();

    return (
        <div>
            {costsData.list && costsData.list.length > 0 ?
                <Table>
                    <TableHeader>
                        <Th>Nr Lotto</Th>
                        <Th>Provenienza</Th>
                        <Th>Dornitore</Th>
                        <Th>Articolo</Th>
                        <Th>Cliente</Th>
                        <Th>Resa (Mq)</Th>
                        <Th>Resa (Pq)</Th>
                        <Th>Resa media vendite</Th>
                        <Th>Costo €/mq</Th>
                        <Th>Costo £/pq</Th>
                        <Th>Ve €/mq</Th>
                        <Th>Ve £/pq</Th>
                        <Th>Diff. %</Th>
                        <Th>N. Mq Vend.</Th>
                        <Th>Costo Mat.Prima</Th>
                    </TableHeader>
                    <tbody>
                    {costsData.list((row,i) =>
                        <CostsRow row={row} key={i} />
                    )}
                    </tbody>
                </Table>
                :
                <EmptyQuery />
            }

        </div>
    );
};

export default CostsTable;