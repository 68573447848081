import {useApp} from "../contexts/app-context";

function useToast() {

    const {setToastContent, setToastStatus} = useApp();

    function disappearingToast(content) {
        setToastContent(content);

        setToastStatus(true);
        setTimeout(() => {
            setToastStatus(false);
        }, 2000, []);
    }

    function toastError(message) {
        disappearingToast(
            <div className="p-1 bg-red-400 mb-4 rounded shadow-xl">
                <div>{message}</div>
            </div>
        );
    }

    function toastConfirm(message) {
        disappearingToast(
            <div className="p-1 bg-secondary text-white font-semibold mb-4 rounded shadow-xl">
                <div>{message}</div>
            </div>
        )
    }

    function toastWarning(message) {
        disappearingToast(
            <div className="p-1 bg-yellow-500 mb-4 rounded shadow-xl z-20">
                <div>{message}</div>
            </div>
        )
    }

    function toastMessage(message, color = 'primary') {
        disappearingToast(
            <div className={`p-1 bg-${color} text-white font-semibold mb-4 rounded shadow-xl`}>
                <div>{message}</div>
            </div>
        )
    }

    return {
        toastError,
        toastConfirm,
        toastWarning,
        toastMessage,
    }
}

export default useToast;
