
function parseDate (date) {
    if(date) {
        let _date = date;
        if(date.includes('T')) {
            _date = date.split('T')[0];
        }
        if(date.includes(' ')) {
            _date = date.split(' ')[0];
        }
        if(_date.includes('-')) {
            _date = _date.split('-');
            return `${_date[2]}/${_date[1]}/${_date[0]}`;
        }
    } else return "";
}

function dateToFileName(date) {
    let _date = parseDate(date);
    return _date.replaceAll("/", "_")
}

function nameToFileName(name) {
    let new_name = name.replaceAll(" ", "_");
    new_name = new_name.replaceAll(".", "_");
    return new_name;
}

function parseFloat5(value) {
    if(value || value === 0) {
        return parseFloat(value).toFixed(5);
    } else {
        return "...";
    }
}

function formatPrice(value) {
    if(value || value === 0) {
        return priceFormat(parseFloat(value).toFixed(2));
    } else {
        return "...";
    }
}

function priceFormat(price) {
    let formatted = new Number(price);
    return formatted.toLocaleString('it-IT', {minimumFractionDigits: 2});
}

function formatInt(num) {
    let formatted = new Number(num);
    return formatted.toLocaleString('it-IT');
}

export {
    parseDate,
    dateToFileName,
    nameToFileName,
    formatPrice,
    parseFloat5,
    priceFormat,
    formatInt,
}