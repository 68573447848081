import React, {useEffect, useState} from 'react';
import Layout from "../components/UI/Layout";
import {useRegistry} from "../utilities/contexts/registry-context";
import useAsync from "../utilities/customHooks/async-call";
import useFilter from "../utilities/customHooks/filter";
import {doGetProcessing, doGetProcesses, doPostProcess, doPutProcess, doDeleteProcess} from "../logics/registry";
import BaseInput from "../components/UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import ProcessesTable from "../components/Processes/ProcessesTable";
import Loading from "../components/UI/Loading";
import useToast from "../utilities/customHooks/toast";
import Modal from "../components/UI/Modal";
import ProcessesForm from "../components/Processes/ProcessesForm";

const Processings = () => {
    const {processes, loadProcesses} = useRegistry();
    const {call} = useAsync();
    const {toastMessage} = useToast();

    const [loading, setLoading] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedProcess, setSelectedProcess] = useState(null);

    const filterByName = (process) => {
        return process.nomelav.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(processes, filterByName);


    const handleCreate = () => {
        setSelectedProcess(null);
        setEditModalOpen(true);
    }

    const handleDelete = async (idlav) => {
        if (!window.confirm('Eliminare lavorazione?')) {
            return;
        }
        await call(
            () => doDeleteProcess(idlav),
            (res) => {
                if (res) {
                    toastMessage("Lavorazione eliminata");
                    reload();
                }
            }
        )
    }
    const handleEdit = async (idlav) => {
        await call(
            () => doGetProcessing(idlav),
            (res) => {
                if (res && res[0]) {
                    console.log(res[0]);
                    setSelectedProcess(res[0]);
                }
            }
        )
        setEditModalOpen(true);
    }

    const handleSubmit = async (params) => {
        console.log(params);

        if (selectedProcess) {
            await call(
                () => doPutProcess(selectedProcess.idlav, params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Lavorazione modificata");
                        reload();
                    }
                }
            );
        } else {
            await call(
                () => doPostProcess(params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Lavorazione creata");
                        reload();
                    }
                }
            );
        }

    }

    const reload = async () => {
        setLoading(true);
        setFilterText("");
        await call(
            () => doGetProcesses(),
            (res) => {
                loadProcesses(res);
            },
        )
        setLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await reload();
        }
        load();
    }, []);

    return (
        <Layout title="Lavorazioni">
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className="mb-4">
                    <BaseInput placeholder={'Cerca lavorazione'}
                               className={'w-full mb-1'}
                               value={filterText}
                               onChange={(e) => setFilterText(e.target.value)}/>
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Lavorazioni elencate:</span>
                        <span>{filteredData.length}</span>
                        <AiOutlineReload onClick={reload} className="hover:text-gray-200 cursor-pointer"
                                         title="Ricarica lavorazioni"/>
                    </div>
                </div>
                {loading ?
                    <Loading label="Caricamento lavorazioni in corso"/>
                    :
                    <div className="h-full overflow-y-auto">
                        <ProcessesTable processes={filteredData} onCreate={handleCreate} onEdit={handleEdit} onDelete={handleDelete}/>
                    </div>
                }
            </div>
            <Modal open={editModalOpen} onOverlayClick={() => setEditModalOpen(false)}>
                <ProcessesForm selected={selectedProcess} onSubmit={handleSubmit}/>
            </Modal>
        </Layout>
    );
};

export default Processings;
