import React, {useEffect, useState} from 'react';
import Layout from "../components/UI/Layout";
import useAsync from "../utilities/customHooks/async-call";
import {useRegistry} from "../utilities/contexts/registry-context";
import SuppliersTable from "../components/Suppliers/SuppliersTable";
import useFilter from "../utilities/customHooks/filter";
import BaseInput from "../components/UI/BaseInput";
import {doDeleteSupplier, doGetSuppliers} from "../logics/registry";
import SupplierPanel from "../components/Suppliers/SupplierPanel";
import Modal from "../components/UI/Modal";
import {AiOutlineReload} from "react-icons/ai";
import NewSupplierForm from "../components/Suppliers/NewSupplierForm";
import Loading from "../components/UI/Loading";
import useToast from "../utilities/customHooks/toast";

const Suppliers = () => {
    const {call} = useAsync();
    const {toastMessage} = useToast();
    const {
        suppliers, loadSuppliers, currentSupplier, setCurrentSupplier,
        panelOpen, setRegistryPanelOpen, createPanelOpen,
        setCreatePanelOpen
    } = useRegistry();

    const [loading, setLoading] = useState(false);

    const filterByName = (supplier) => {
        return supplier.ragsocf.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(suppliers, filterByName);

    const handleDelete = async (idforn) => {
        if (!window.confirm('Eliminare fornitore?')) {
            return;
        }
        await call(
            () => doDeleteSupplier(idforn),
            (res) => {
                if (res) {
                    toastMessage("Fornitore eliminato.");
                    reload();
                }
            }
        )
    }

    const reload = async () => {
        setLoading(true);
        setFilterText("");
        await call(() => doGetSuppliers(),
            (res) => loadSuppliers(res)
        )
        setLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await reload();
        }
        load();
    }, []);

    return (
        <Layout title={'Fornitori'}>
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className="mb-4">
                    <BaseInput
                        placeholder={'Cerca fornitore'}
                        className={'w-full mb-1'}
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}/>
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Fornitori elencati:</span>
                        <span>{filteredData.length}</span>
                        <AiOutlineReload
                            onClick={reload}
                            className="hover:text-gray-200 cursor-pointer"
                            title="Ricarica fornitori"/>
                    </div>
                </div>
                {loading
                    ? <Loading label={'Caricamento fornitori in corso'}/>
                    : <div className="h-full overflow-y-auto">
                        <SuppliersTable
                            suppliers={filteredData}
                            onDelete={handleDelete}/>
                    </div>
                }
            </div>

            <Modal
                open={panelOpen}
                onOverlayClick={() => {
                    setCurrentSupplier(null);
                    setRegistryPanelOpen(false)
                }}>
                <SupplierPanel supplier={currentSupplier}/>
            </Modal>

            <Modal
                open={createPanelOpen}
                onOverlayClick={() => setCreatePanelOpen(false)}>
                <NewSupplierForm/>
            </Modal>

        </Layout>
    )
}

export default Suppliers;
