import React, {useState, useEffect} from 'react';
import {useLot} from "../../utilities/contexts/lot-context";
import useAsync from "../../utilities/customHooks/async-call";
import {doDeleteLot, doGetLotDeleteInformation} from "../../logics/lots";
import Modal from "../UI/Modal";
import Row from "../UI/Structure/Row";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import useToast from "../../utilities/customHooks/toast";

const DeleteLotModal = ({onSubmit}) => {

    const {modal, openModal, currentLot} = useLot();
    const {call} = useAsync();
    const {toastMessage} = useToast();


    const [loading, setLoading] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(null);

    const deleteLot = async () => {
        console.log("delete lot");

        await call(
            () => doDeleteLot(currentLot.nr),
            (res) => {
                if(res) {
                    toastMessage("Lotto eliminato", "red-500");
                    openModal('lot_delete', false);
                    onSubmit && onSubmit();
                }
            },
            () => {
                toastMessage("Error: cancellazione non possibile")
            });
    }


    useEffect(() => {

        const loadDeleteInfo = async () => {
            setLoading(true);
            await call(
                () => doGetLotDeleteInformation(currentLot.nr),
                (res) => {
                    if (res) {
                        setDeleteInfo(res);
                    }

                }
            );
            setLoading(false);
        }

        if (modal && modal.lot_delete) {
            loadDeleteInfo();
        }
    }, [modal.lot_delete])

    return (
        <Modal open={modal.lot_delete} onOverlayClick={() => openModal('lot_delete', false)}>
            <div className="font-bold text-lg">Cancellazione lotto {currentLot && currentLot.nr}</div>

            <div className="mb-2">
                {loading ?
                    <Loading/>
                    :
                    deleteInfo &&
                    <div>
                        <div>Righe del lotto: {deleteInfo.rows}</div>
                        <div>Movimenti del lotto: {deleteInfo.movements}</div>
                    </div>
                }
            </div>

            <Row className="justify-between">
                <Button onClick={() => openModal('lot_delete', false)}>Esci</Button>
                <Button onClick={deleteLot}>Elimina</Button>
            </Row>
        </Modal>
    );
};

export default DeleteLotModal;