import useAsync from "../../utilities/customHooks/async-call";
import useToast from "../../utilities/customHooks/toast";
import {useRegistry} from "../../utilities/contexts/registry-context";
import useFilter from "../../utilities/customHooks/filter";
import {doDeleteClient, doGetClient, doGetClients} from "../../logics/registry";
import React, {useEffect, useState} from "react";
import BaseInput from "../UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import ClientTable from "./ClientTable";
import Modal from "../UI/Modal";
import ClientPanel from "./ClientPanel";
import NewClientForm from "./NewClientForm";
import Loading from "../UI/Loading";

const ClientsList = () => {
    const {call} = useAsync();
    const {toastMessage} = useToast();
    const {
        clients,
        loadClients,
        panelOpen,
        currentClient,
        setRegistryPanelOpen,
        setCurrentClient,
        createPanelOpen,
        setCreatePanelOpen
    } = useRegistry();
    const [loading, setLoading] = useState(false);


    const filterByName = (client) => {
        return client.ragsoccl.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(clients, filterByName);

    const handleCreate = () => {
        setCurrentClient(null);
        setCreatePanelOpen(true);
    }

    const handleEdit = async (idcl) => {
        await call(
            async () => await doGetClient(idcl),
            (res) => {
                if (res && res[0]) {
                    setCurrentClient(res[0]);
                    setRegistryPanelOpen(true);
                }
            }
        )
    }

    const handleDelete = async (idcl) => {
        if (!window.confirm('Eliminare cliente?')) {
            return;
        }
        await call(
            () => doDeleteClient(idcl),
            (res) => {
                if (res) {
                    toastMessage("Cliente eliminato.");
                    reload();
                }
            }
        )
    }

    const reload = async () => {
        setLoading(true);
        setFilterText("");
        await call(
            () => doGetClients(),
            (clients) => loadClients(clients),
            (error) => console.log(error),
        )
        setLoading(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            await reload();
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className='mb-4'>
                    <BaseInput placeholder={'Cerca cliente'}
                               className={'w-full mb-1'}
                               value={filterText}
                               onChange={(e) => setFilterText(e.target.value)}/>
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Clienti elencati:</span>
                        <span>{filteredData.length}</span>
                        <AiOutlineReload
                            onClick={reload}
                            className="hover:text-gray-200 cursor-pointer"
                            title="Ricarica clienti"/>
                    </div>
                </div>
                {loading ?
                    <Loading label="Caricamento clienti in corso"/>
                    : <div className="h-full overflow-y-auto"><ClientTable
                        clients={filteredData}
                        onCreate={handleCreate}
                        onEdit={handleEdit}
                        onDelete={handleDelete}/>
                    </div>
                }
            </div>

            <Modal
                open={panelOpen}
                onOverlayClick={() => {
                    setRegistryPanelOpen(false);
                    setCurrentClient(null)
                }}>
                <ClientPanel client={currentClient}/>
            </Modal>

            <Modal
                open={createPanelOpen}
                onOverlayClick={() => setCreatePanelOpen(false)}>
                <NewClientForm/>
            </Modal>
        </>
    )
}

export default ClientsList;
