import React from 'react';
import Section from "../../UI/Structure/Section";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import BaseInput from "../../UI/BaseInput";
import RowForm from "../../UI/Form/RowForm";

const Search = () => {

    const handleSubmit = (e) => {

    }

    return (
        <Section collapsable={true} label="Partitari">
            <RowForm onSumit={handleSubmit}>
                <VerticalLabelledField label="Dal">
                    <BaseInput type="date" />
                </VerticalLabelledField>
                <VerticalLabelledField label="Al">
                    <BaseInput type="date" />
                </VerticalLabelledField>
                <VerticalLabelledField label="Nr. lotto">
                    <BaseInput placeholder="Numero lotto"/>
                </VerticalLabelledField>
            </RowForm>
        </Section>
    );
};

export default Search;