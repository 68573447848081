import React from 'react';
import Section from "../../UI/Structure/Section";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import BaseInput from "../../UI/BaseInput";
import SelectClient from "../../UI/Selects/SelectClient";
import LotCategorySelect from "../../Queries/General/LotCategorySelect";
import RowForm from "../../UI/Form/RowForm";
import {useReport} from "../../../utilities/contexts/report-context";

const Search = ({onSubmit}) => {

    const {costsFormData, setCostsForm} = useReport();

    const handleSubmit = (e) => {
        e.preventDefault();

        onSubmit && onSubmit();
    }

    const handleStartChange = (e) => {
        setCostsForm(e.target.value, costsFormData.date_to, costsFormData.client, costsFormData.nrlotto)
    }

    const handleEndChange = (e) => {
        setCostsForm(costsFormData.date_from, e.target.value, costsFormData.client, costsFormData.nrlotto)
    }

    const handleClientChange = (client) => {
        setCostsForm(costsFormData.date_from, costsFormData.date_to, client, costsFormData.nrlotto)
    }

    const handleLotChange = (e) => {
        setCostsForm(costsFormData.date_from, costsFormData.date_to, costsFormData.client, e.target.value)
    }

    return (
        <Section collapsable={true} label="Resoconto costi lavorazioni">
            <RowForm onSubmit={handleSubmit}>
                <VerticalLabelledField label="Dal">
                    <BaseInput type="date" value={costsFormData.date_from} onChange={handleStartChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Al">
                    <BaseInput type="date" value={costsFormData.date_to} onChange={handleEndChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Cliente">
                    <SelectClient value={costsFormData.client} onChange={handleClientChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Ricerca per gruppo di lotti">
                    <LotCategorySelect classname="w-full" value={costsFormData.nrlotto} onChange={handleLotChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Ricerca per nr. lotto">
                    <BaseInput type="text" placeholder="Nr.Lotto" value={costsFormData.nrlotto} onChange={handleLotChange}/>
                </VerticalLabelledField>
            </RowForm>
        </Section>
    );
};

export default Search;
