const NODE_HEIGHT = 20;

function findNode(tree, idnodo) {
    if(tree.idnodo === idnodo) {
        return tree;
    } else {
        if(tree.children.length === 0) {
            return null;
        } else {
            let foundNode = null;
            for(let i = 0; i < tree.children.length && !foundNode; i++) {
                foundNode = findNode(tree.children[i], idnodo);
            }

            return foundNode;
        }
    }
}

function findLastLeafOfTree(tree) {
    let children = tree.children.length;
    if(children === 0) {
        return tree;
    } else {
        return findLastLeafOfTree(tree.children[children - 1]);
    }
}

function next(tree, idnodo) {
    let node = findNode(tree, idnodo);
    if(node) {
        if(node.children && node.children.length > 0) {
            return node.children[0];
        } else {
            return nextSibling(tree, node);
        }
    }

    return null;
}

function nextSibling(tree, node) {
    if(node.father) {
        let father = findNode(tree, node.father.idnodo);
        if(father && father.children) {
            let siblings = father.children.length;
            if(siblings > 1 && node.index + 1 < siblings) {
                return father.children[node.index +1];
            } else return nextSibling(tree, father);
        } else {
            return null;
        }
    } else return null;
}

function prev(tree, idnodo) {

    let node = findNode(tree, idnodo);

    if (node.father) {
        let father = treeData.findNode(tree, node.father.idnodo);
        if (father) {
            if (node.index > 0) {
                let sibling = father.children[node.index - 1]
                if (sibling) {
                    return treeData.findLastLeafOfTree(sibling);
                } else {
                    return null;
                }
            } else {
                return father;
            }
        }
    }
}

const treeData = {
    NODE_HEIGHT,
    findNode,
    findLastLeafOfTree,
    next,
    prev,
}

export default treeData;