import React from 'react';
import Section from "../../UI/Structure/Section";
import Row from "../../UI/Structure/Row";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import BaseInput from "../../UI/BaseInput";
import Button from "../../UI/Button";
import SelectClient from "../../UI/Selects/SelectClient";
import LotCategorySelect from "../../Queries/General/LotCategorySelect";
import {useReport} from "../../../utilities/contexts/report-context";
import RowForm from "../../UI/Form/RowForm";

const Search = ({onSubmit}) => {

    const {salesFormData, setSalesForm} = useReport();

    const handleDateFromChange = (e) => {
        setSalesForm(e.target.value, salesFormData.date_to, salesFormData.client, salesFormData.nrlotto);
    }
    const handleDateToChange = (e) => {
        setSalesForm(salesFormData.date_from, e.target.value, salesFormData.client, salesFormData.nrlotto);
    }
    const handleClientChange = (client) => {
        setSalesForm(salesFormData.date_from, salesFormData.date_to, client, salesFormData.nrlotto);
    }
    const handleNrLottoChange = (e) => {
        setSalesForm(salesFormData.date_from, salesFormData.date_to, salesFormData.client, e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        onSubmit && onSubmit();
    }

    return (
        <Section collapsable={true} label="Resoconto vendite">
            <RowForm onSubmit={handleSubmit}>
                <VerticalLabelledField label="Dal">
                    <BaseInput type="date" required value={salesFormData.date_from} onChange={handleDateFromChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Al">
                    <BaseInput type="date" required value={salesFormData.date_to} onChange={handleDateToChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Cliente">
                    <SelectClient value={salesFormData.client} onChange={handleClientChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Ricerca per gruppo di lotti">
                    <LotCategorySelect classname="w-full" value={salesFormData.nrlotto} onChange={handleNrLottoChange}/>
                </VerticalLabelledField>
                {/*<div className="leading-none text-xs " style={{fontSize: "10px"}}>oppure</div>*/}
                <VerticalLabelledField label="Ricerca per nr. lotto">
                    <BaseInput type="text" placeholder="Nr.Lotto" value={salesFormData.nrlotto} onChange={handleNrLottoChange} />
                </VerticalLabelledField>
            </RowForm>
        </Section>
    );
};

export default Search;