import API from "./API";

function doGetSales(date_from, date_to, client, nrlotto) {
    let params = new URLSearchParams();
    date_from && params.append('date_from', date_from);
    date_to && params.append('date_to', date_to);
    client?.id && params.append('idcl', client.id)
    nrlotto && params.append('nrlotto', nrlotto);
    return API.get('/reports/sales', params);
}

function doGetProcessingCosts(date_from, date_to, client, nrlotto) {
    let params = new URLSearchParams();
    date_from && params.append('date_from', date_from);
    date_to && params.append('date_to', date_to);
    client?.id && params.append('idcl', client.id)
    nrlotto && params.append('nrlotto', nrlotto);
    return API.get('/reports/processing_costs', params);
}

export {
    doGetSales,
    doGetProcessingCosts,
}