import React from 'react';
import TableRow from "../../UI/table/TableRow";

const CostsRow = ({row}) => {
    return (
        <TableRow>
            <td>{row.nrlotto}</td>
            <td>{row.buying_article}</td>
            <td>{row.client}</td>
            <td>Articolo</td>
            <td>Cliente</td>
            <td>Resa (Mq)</td>
            <td>Resa (Pq)</td>
            <td>Resa media vendite</td>
            <td>Costo €/mq</td>
            <td>Costo £/pq</td>
            <td>Ve €/mq</td>
            <td>Ve £/pq</td>
            <td>Diff. %</td>
            <td>N. Mq Vend.</td>
            <td>Costo Mat.Prima</td>
        </TableRow>
    );
};

export default CostsRow;