import {useEffect, useState} from "react";
import {doDeleteSubcontractor, doGetSubcontractors} from "../../logics/registry";
import useFilter from "../../utilities/customHooks/filter";
import useAsync from "../../utilities/customHooks/async-call";
import {useRegistry} from "../../utilities/contexts/registry-context";
import BaseInput from "../UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import SubcontractorsTable from "./SubcontractorsTable";
import Modal from "../UI/Modal";
import SubcontractorPanel from "./SubcontractorPanel";
import NewSubcontractorForm from "./NewSubcontractorForm";
import useToast from "../../utilities/customHooks/toast";
import Loading from "../UI/Loading";

const Subcontractors = () => {
    const {
        subcontractors,
        loadSubcontractors,
        panelOpen,
        setRegistryPanelOpen,
        currentSubcontractor,
        setCurrentSubcontractor,
        createPanelOpen,
        setCreatePanelOpen
    } = useRegistry();
    const {toastMessage} = useToast();
    const {call} = useAsync();
    const [loading, setLoading] = useState(false);

    const filterByName = (supplier) => {
        return supplier.ragsoctz.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(subcontractors, filterByName);

    const handleDelete = async (idterz) => {
        if (!window.confirm('Eliminare terzista?')) {
            return;
        }
        await call(
            () => doDeleteSubcontractor(idterz),
            (res) => {
                if (res) {
                    toastMessage("Terzista eliminato.");
                    reload();
                }
            }
        )
    }

    const reload = async () => {
        setLoading(true)
        setFilterText("");
        await call(
            () => doGetSubcontractors(),
            (res) => loadSubcontractors(res),
        )
        setLoading(false)
    }

    useEffect(() => {
        const load = async () => {
            await reload();
        }
        load();
    }, []);

    return (
        <>
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className="mb-4">
                    <BaseInput
                        placeholder={'Cerca terzista'}
                        className={'w-full mb-1'}
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}/>
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Terzisti elencati:</span>
                        <span>{filteredData.length}</span>
                        <AiOutlineReload
                            onClick={reload}
                            className="hover:text-gray-200 cursor-pointer"
                            title="Ricarica terzisti"/>
                    </div>
                </div>

                {loading
                    ? <Loading label={"Caricamento terzisti in corso"}/>
                    : <div className="h-full overflow-y-auto">
                        <SubcontractorsTable
                            subcontractors={filteredData}
                            onDelete={handleDelete}/>
                    </div>
                }
            </div>

            <Modal
                open={panelOpen}
                onOverlayClick={() => {
                    setRegistryPanelOpen(false);
                    setCurrentSubcontractor(null)
                }}>
                <SubcontractorPanel subcontractor={currentSubcontractor}/>
            </Modal>

            <Modal
                open={createPanelOpen}
                onOverlayClick={() => setCreatePanelOpen(false)}>
                <NewSubcontractorForm/>
            </Modal>
        </>
    )
}

export default Subcontractors;
