import React from 'react';
import Button from "../../UI/Button";
import FirstMovementCreation from "./Creation/FirstMovementCreation";
import {useLot} from "../../../utilities/contexts/lot-context";

const FirstMovement = () => {
    const { modal, openModal} = useLot();

    return (
        <div>
            <div>Nessun movimento disponibile</div>
            <Button
                onClick={() => openModal("createMovement", true)}>
                Crea il primo movimento
            </Button>

            <FirstMovementCreation open={modal.createMovement}/>
        </div>
    );
};

export default FirstMovement;
