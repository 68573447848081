import React from 'react';
import TableRow from "../UI/table/TableRow";
import {FaTrashAlt} from "react-icons/fa";


const ProcessRow = ({process, onClick, onDelete}) => {
    const handleClick = async () => {
        onClick && onClick(process.idlav);
    }

    const handleDelete = async (event) => {
        event.stopPropagation();
        onDelete && onDelete(process.idlav)
    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">
                {process.nomelav}
            </td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt
                        title="Cancella Lavorazione"
                        onClick={handleDelete}
                        className="cursor-pointer hover:text-red-500"
                    />
                </div>
            </td>
        </TableRow>
    )
};

export default ProcessRow;
