import {FaTrashAlt} from "react-icons/fa";
import TableRow from "../../UI/table/TableRow";


const ClientArticlesRow = ({clientArticle, onClick, onDelete}) => {
    const handleRowClick = async () => {
        onClick && onClick(clientArticle.idclart);
    }

    const handleDelete = async (event) => {
        event.stopPropagation();
        onDelete && onDelete(clientArticle.idclart)
    }


    return (
        <TableRow onClick={handleRowClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">
                {clientArticle.article.nomeart}
            </td>
            <td className="pl-2 ">
                {clientArticle?.color?.nomecol ?? "-"}
            </td>
            <td className="pl-2 ">
                {clientArticle.data}
            </td>
            <td className="pl-2 ">
                {clientArticle.prezzo1}
            </td>
            <td className="pl-2 ">
                {clientArticle?.choise1?.nomescelta ?? "-"}
            </td>
            <td className="pl-2 ">
                {clientArticle.lirepiede1}
            </td>
            <td className="pl-2 ">
                {clientArticle.prezzo2}
            </td>
            <td className="pl-2 ">
                {clientArticle?.choise2?.nomescelta ?? "-"}
            </td>
            <td className="pl-2 ">
                {clientArticle.lirepiede2}
            </td>
            <td className="pl-2 ">
                {clientArticle.prezzo3}
            </td>
            <td className="pl-2 ">
                {clientArticle?.choise3?.nomescelta ?? "-"}
            </td>
            <td className="pl-2 ">
                {clientArticle.lirepiede3}
            </td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt
                        title="Cancella Articolo"
                        onClick={handleDelete}
                        className="cursor-pointer hover:text-red-500"
                    />
                </div>
            </td>
        </TableRow>
    )
};

export default ClientArticlesRow;
