import API from "./API";

async function doGetClients() {
  return await API.get('/clients');
}

async function doGetClient(id) {
  return await API.get(`/clients/${id}`);
}

async function doPutClient(id, data) {
  return await API.put(`/clients/${id}`, data);
}

async function doPostClient(data) {
  return await API.post(`/clients/`, data);
}

async function doDeleteClient(id) {
  return await API.DELETE(`/clients/${id}`);
}

async function doGetClientArticles(idcl) {
  return API.get(`/clients/${idcl}/articles/colors`);
}

async function doGetClientArticle(idclart) {
  return API.get(`/clients/articles/colors/${idclart}`);
}

async function doPostClientArticle(params) {
  return API.post(`/clients/articles/colors`, params);
}

async function doPutClientArticle(idclart, params) {
  return API.put(`/clients/articles/colors/${idclart}`, params);
}

async function doDeleteClientArticle(idclart) {
  return API.DELETE(`/clients/articles/colors/${idclart}`);
}

async function doGetSuppliers() {
  return API.get('/suppliers');
}

async function doGetSupplier(id) {
  return API.get(`/suppliers/${id}`)
}

async function doPutSupplier(id, data) {
  return API.put(`/suppliers/${id}`, data);
}

async function doPostSupplier(params) {
  return API.post('/suppliers/', params);
}

async function doDeleteSupplier(id) {
  return API.DELETE(`/suppliers/${id}`)
}

async function doGetSubcontractors() {
  return API.get('/subcontractors');
}

async function doGetSubcontractor(id) {
  return API.get(`/subcontractors/${id}`);
}

async function doPutSubcontractor(id, data) {
  return API.put(`/subcontractors/${id}`, data);
}

async function doPostSubcontractor(params) {
  return API.post('/subcontractors/', params);
}

async function doDeleteSubcontractor(id) {
  return API.DELETE(`/subcontractors/${id}`);
}

async function doGetSubcontracorProcesses(IdTerz) {
  return API.get(`/subcontractors/${IdTerz}/processings`);
}

async function doGetSubcontracorProcessing(IdTerz, IdLav) {
  return API.get(`/subcontractors/${IdTerz}/processings/${IdLav}`);
}

async function doPostSubcontracorProcessing(params) {
  return API.post(`/subcontractors/processings`, params);
}

async function doPutSubcontracorProcessing(IdTerzLav, params) {
  return API.put(`/subcontractors/processings/${IdTerzLav}`, params);
}

async function doDeleteSubcontracorProcessing(IdTerzLav) {
  return API.DELETE(`/subcontractors/processings/${IdTerzLav}`);
}

async function doGetDocumentTypes() {
  return API.get('/document_types');
}

async function doGetProcesses() {
  return API.get('/processings');
}

async function doGetProcessing(id) {
  return API.get(`/processings/${id}`);
}

async function doPostProcess(params) {
  return API.post('/processings', params);
}

async function doPutProcess(idlav, params) {
  return API.put(`/processings/${idlav}`, params);
}

async function doDeleteProcess(idlav) {
  return API.DELETE(`/processings/${idlav}`);
}

async function doGetArticles() {
  return API.get('/articles');
}

async function doGetArticle(id) {
  return API.get(`/articles/${id}`);
}

async function doPostArticle(params) {
  return API.post('/articles', params);
}

async function doPutArticle(idart, data) {
  return API.put(`/articles/${idart}`, data);
}

async function doDeleteArticle(idart) {
  return API.DELETE(`/articles/${idart}`);
}

async function doGetColors() {
  return API.get('/colors');
}

async function doGetColor(id) {
  return API.get(`/colors/${id}`);
}

async function doPostColor(data) {
  return API.post(`/colors`, data);
}

async function doPutColor(id, data) {
  return API.put(`/colors/${id}`, data);
}

async function doDeleteColor(id) {
  return API.DELETE(`/colors/${id}`);
}

async function doGetMeasureUnits() {
  return API.get('/measure_units');
}

async function doGetChoices() {
  return API.get('/choices');
}

export {
  doGetClients,
  doGetClient,
  doPutClient,
  doPostClient,
  doGetClientArticles,
  doGetClientArticle,
  doPostClientArticle,
  doPutClientArticle,
  doDeleteClientArticle,
  doDeleteClient,
  doGetSuppliers,
  doGetSupplier,
  doPutSupplier,
  doPostSupplier,
  doDeleteSupplier,
  doGetSubcontractors,
  doGetSubcontractor,
  doPutSubcontractor,
  doPostSubcontractor,
  doDeleteSubcontractor,
  doGetSubcontracorProcesses,
  doGetSubcontracorProcessing,
  doPostSubcontracorProcessing,
  doPutSubcontracorProcessing,
  doDeleteSubcontracorProcessing,
  doGetDocumentTypes,
  doGetProcesses,
  doGetProcessing,
  doPostProcess,
  doPutProcess,
  doDeleteProcess,
  doGetArticles,
  doGetArticle,
  doPostArticle,
  doPutArticle,
  doDeleteArticle,
  doGetColors,
  doGetColor,
  doPostColor,
  doPutColor,
  doDeleteColor,
  doGetMeasureUnits,
  doGetChoices,
}
