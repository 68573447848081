import {BiPlus} from "react-icons/bi";
import ClientArticlesRow from "./ClientArticlesRow";

const ClientArticlesTable = ({clientArticles, onCreate, onEdit, onDelete}) => {

    const handleCreate = () => {
        onCreate && onCreate();
    }

    const handleEdit = (idclart) => {
        onEdit && onEdit(idclart);
    }
    const handleDelete = (idclart) => {
        onDelete && onDelete(idclart);
    }
    return (
        <table className="w-full text-left">
            <thead>
            <tr className="border-b border-gray-600">
                <th className="pl-2 ">Articolo</th>
                <th className="pl-2 ">Colore</th>
                <th className="pl-2 ">Data</th>
                <th className="pl-2 ">Prezzo nr.1</th>
                <th className="pl-2 ">Scleta 1</th>
                <th className="pl-2 ">Lire/Pq</th>
                <th className="pl-2 ">Prezzo nr.2</th>
                <th className="pl-2 ">Scleta 2</th>
                <th className="pl-2 ">Lire/Pq</th>
                <th className="pl-2 ">Prezzo nr.3</th>
                <th className="pl-2 ">Scleta 3</th>
                <th className="pl-2 ">Lire/Pq</th>
                <th>
                    <span className="flex flex-row justify-center items-center w-full">
                        <BiPlus
                            onClick={handleCreate}
                            title={'Associa articolo'}
                            className="cursor-pointer hover:text-green-400"
                        />
                    </span>
                </th>
            </tr>
            </thead>
            <tbody className=" w-full ">
            {clientArticles && clientArticles.length > 0 &&
                clientArticles.map((clientArticle, i) =>
                    <ClientArticlesRow clientArticle={clientArticle} key={i} onClick={handleEdit} onDelete={handleDelete}/>
                )
            }
            </tbody>
        </table>
    );
};

export default ClientArticlesTable;
