import React, {useEffect, useState} from 'react';
import {useRegistry} from "../utilities/contexts/registry-context";
import useAsync from "../utilities/customHooks/async-call";
import useFilter from "../utilities/customHooks/filter";
import {
    doDeleteColor,
    doGetColor,
    doGetColors,
    doPostColor,
    doPutColor,
} from "../logics/registry";
import Layout from "../components/UI/Layout";
import BaseInput from "../components/UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import ColorsTable from "../components/Colors/ColorsTable";
import Loading from "../components/UI/Loading";
import Modal from "../components/UI/Modal";
import ColorForm from "../components/Colors/ColorForm";
import useToast from "../utilities/customHooks/toast";

const Colors = () => {
    const {colors, loadColors} = useRegistry();
    const {call} = useAsync();
    const {toastMessage} = useToast();

    const [loading, setLoading] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);

    const filterByName = (color) => {
        return color.nomecol.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(colors, filterByName);

    const handleCreate = () => {
        setEditModalOpen(true);
        setSelectedColor(null);
    }

    const handleEdit = async (idcol) => {
        await call(
            () => doGetColor(idcol),
            (res) => {
                if (res && res[0]) {
                    setSelectedColor(res[0]);
                }
            }
        )
        setEditModalOpen(true);
    }

    const handleDelete = async (idcol) => {
        if (!window.confirm('Eliminare colore?')) {
            return;
        }
        console.log(idcol)
        await call(
            () => doDeleteColor(idcol),
            (res) => {
                if (res) {
                    toastMessage("Colore eliminato");
                    reload();
                }
            }
        )
    }

    const handleSubmit = async (params) => {
        console.log(params);

        if (selectedColor) {
            await call(
                () => doPutColor(selectedColor.idcol, params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Colore modificato");
                        reload();
                    }
                }
            );
        } else {
            await call(
                () => doPostColor(params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Colore creato");
                        reload();
                    }
                }
            );
        }

    }

    const reload = async () => {
        setLoading(true);
        setFilterText("");
        await call(
            () => doGetColors(),
            (res) => {
                loadColors(res);
            },
        );
        setLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await reload();
        }

        load();
    }, []);

    return (
        <Layout title="Colori">
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className="mb-4">
                    <BaseInput placeholder={'Cerca colore'}
                               className={'w-full mb-1'}
                               value={filterText}
                               onChange={(e) => setFilterText(e.target.value)}/>
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Colori elencati:</span>
                        <span>{filteredData.length}</span>
                        <AiOutlineReload
                            onClick={reload}
                            className="hover:text-gray-200 cursor-pointer"
                            title="Ricarica colori"/>
                    </div>
                </div>
                {loading ?
                    <Loading label="Caricamento colori in corso"/>
                    :
                    <div className="h-full overflow-y-auto">
                        <ColorsTable
                            colors={filteredData}
                            onCreate={handleCreate}
                            onEdit={handleEdit}
                            onDelete={handleDelete}/>
                    </div>
                }
            </div>
            <Modal open={editModalOpen} onOverlayClick={() => setEditModalOpen(false)}>
                <ColorForm color={selectedColor} onSubmit={handleSubmit}/>
            </Modal>
        </Layout>
    );
};

export default Colors;
