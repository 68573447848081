import {useRegistry} from "../../../utilities/contexts/registry-context";
import useAsync from "../../../utilities/customHooks/async-call";
import useToast from "../../../utilities/customHooks/toast";
import React, {useEffect, useState} from "react";
import useFilter from "../../../utilities/customHooks/filter";
import {
    doDeleteClientArticle,
    doGetClientArticle, doGetClientArticles,
    doPostClientArticle,
    doPutClientArticle,
} from "../../../logics/registry";
import BaseInput from "../../UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import Loading from "../../UI/Loading";
import Modal from "../../UI/Modal";
import {useMatch, useNavigate, useParams} from "react-router-dom";
import ClientArticlesTable from "./ClientArticlesTable";
import ClientArticlesForm from "./ClientArticlesForm";
import {BiArrowBack} from "react-icons/bi";

const ClientArticles = () => {
    const {
        currentClient,
        setCurrentClient,
        clientArticles,
        loadClientArticles
    } = useRegistry();
    const {call} = useAsync();
    const {toastMessage} = useToast();
    const routeParams = useParams();
    const navigate = useNavigate();
    const matchPath = useMatch('/clienti/:idcl');

    const [loading, setLoading] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedClientArticle, setSelectedClientArticle] = useState(null);

    const filterByName = (clientArticle) => {
        return clientArticle.article.nomeart.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(clientArticles, filterByName);

    const handleCreate = () => {
        setSelectedClientArticle(null);
        setEditModalOpen(true);
    }

    const handleDelete = async (idclart) => {
        if (!window.confirm('Eliminare articolo?')) {
            return;
        }
        await call(
            () => doDeleteClientArticle(idclart),
            (res) => {
                if (res) {
                    toastMessage("Articolo eliminata");
                    reload();
                }
            }
        )
    }
    const handleEdit = async (idclart) => {
        await call(
            () => doGetClientArticle(idclart),
            (res) => {
                if (res[0]) {
                    setSelectedClientArticle(res[0]);
                }
            }
        )
        setEditModalOpen(true);
    }

    const handleSubmit = async (params) => {

        if (selectedClientArticle) {
            await call(
                () => doPutClientArticle(selectedClientArticle.idclart, params),
                (res) => {
                    console.log("sex")
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Articolo modificata");
                        reload();
                    }
                }
            );
        } else {
            await call(
                () => doPostClientArticle(params),
                (res) => {
                    if (res) {
                        setEditModalOpen(false);
                        toastMessage("Articolo creata");
                        reload();
                    }
                }
            );
        }
    }

    const reload = async () => {
        setLoading(true);
        setFilterText("");
        await call(
            () => doGetClientArticles(routeParams.idcl),
            (clientArticles) => loadClientArticles(clientArticles),
            (error) => console.log(error)
        )
        setLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await reload();
        }
        load();
    }, []);

    const patternMatch = () => {
        return matchPath && matchPath.pattern;
    }

    const goBack = () => {
        setCurrentClient(null);
        navigate('/clienti/lista', {replace: true});
    }

    const goList = () => {
        setCurrentClient(currentClient);
        navigate(`/clienti/${routeParams.idcl}`, {replace: true});
    }

    return (
        <>
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className="flex flex-row gap-2 items-center bg-secondary bg-opacity-50 pl-2">
                    <button onClick={goBack} title="Torna alla lista"><BiArrowBack/></button>
                    <button
                        onClick={goList}
                        title="Vai a articoli cliente "
                        className={`${patternMatch() ? "font-bold underline" : ""}`}
                    >
                        Articoli di {currentClient?.ragsoccl}
                    </button>
                </div>
                <div className="mb-4">
                    <BaseInput
                        placeholder={'Cerca articolo'}
                        className={'w-full mb-1'}
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Articoli elencati:</span>
                        <span>{filteredData?.length ?? 0}</span>
                        <AiOutlineReload
                            onClick={reload}
                            className="hover:text-gray-200 cursor-pointer"
                            title="Ricarica articoli"
                        />
                    </div>
                </div>
                {loading
                    ? <Loading label="Caricamento articoli in corso"/>
                    : <div className="h-full overflow-y-auto">
                        <ClientArticlesTable
                            clientArticles={filteredData ?? []}
                            onCreate={handleCreate}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    </div>
                }
            </div>
            <Modal open={editModalOpen} onOverlayClick={() => setEditModalOpen(false)}>
                <ClientArticlesForm selected={selectedClientArticle} onSubmit={handleSubmit}/>
            </Modal>
        </>
    );
}

export default ClientArticles;
