
import API from "./API";

async function doGetLots(params = "") {
    return await API.get(`/lots/${params}`);
}

async function doGetPaginatedLots(url) {
    return await API.getUrl(url);
}

async function doGetLot(id) {
    return await API.get(`/lots/${id}`);
}

async function doGetLotHeader(nr) {
    return await API.get(`/lots/${nr}/header`)
}

async function doPostLotHeader(fields) {
    return await API.post('/lots/header', fields);
}

async function doPutLotHeader(nr, fields) {
    return await API.put(`/lots/${nr}/header`, fields);
}

async function doGetSuggestedLotNumber() {
    return await API.get('/lots/new_number');
}

async function doGetLotRows(nr) {
    return await API.get(`/lots/${nr}/rows`);
}

async function doPutLotRow(nr, row_id, fields) {
    return await API.put(`/lots/${nr}/rows/${row_id}`, fields);
}

async function doPostLotRow(nr, fields) {
    return await API.post(`/lots/${nr}/rows`, fields);
}

async function doDeleteLotRow(nr, row_id) {
    return await API.DELETE(`/lots/${nr}/rows/${row_id}`);
}

async function doGetMovement(nr, movement_id) {
    return await API.get(`/lots/${nr}/movements/${movement_id}`);
}

async function doGetLotMovements(lot_nr) {
    return await API.get(`/lots/${lot_nr}/tree/`);
}

async function doPutLotMovement(nr, movement_id, fields) {
    return await API.put(`/lots/${nr}/movements/${movement_id}`, fields);
}
async function doPostLotMovement(nr, movement_id, fields) {
    return await API.post(`/lots/${nr}/movement/${movement_id}`, fields);
}

async function doGetLotBalance(nr) {
    return await API.get(`/lots/${nr}/balance`);
}

async function doPostLotFirstMovement(nr, fields) {
    return await API.post(`/lots/${nr}/movements/first`, new URLSearchParams({...fields}));
}

async function doGetLotDeleteInformation(nr) {
    return await API.get(`/lots/delete_info/${nr}`);
}

async function doDeleteLot(nr) {
    return await API.DELETE(`/lots/${nr}`);
}

export {
    doGetLots,
    doGetPaginatedLots,
    doGetLot,
    doGetLotHeader,
    doPostLotHeader,
    doPutLotHeader,
    doGetSuggestedLotNumber,
    doGetLotRows,
    doPutLotRow,
    doPostLotRow,
    doDeleteLotRow,
    doGetMovement,
    doGetLotMovements,
    doPutLotMovement,
    doPostLotMovement,
    doGetLotBalance,
    doPostLotFirstMovement,
    doGetLotDeleteInformation,
    doDeleteLot,
}