import React from 'react';
import TableRow from "../UI/table/TableRow";
import {doGetSubcontractor} from "../../logics/registry";
import {useRegistry} from "../../utilities/contexts/registry-context";
import useAsync from "../../utilities/customHooks/async-call";
import {FaCubes, FaTrashAlt} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const SubcontractorRow = ({subcontractor, onDelete, ...props}) => {
    const navigate = useNavigate();

    const {setCurrentSubcontractor, setRegistryPanelOpen} = useRegistry();
    const {call} = useAsync();

    const handleClick = async () => {
        await call(
            () => doGetSubcontractor(subcontractor.idterz),
            (res) => {
                if (res && res[0]) {
                    setCurrentSubcontractor(res[0]);
                    setRegistryPanelOpen(true);
                }
            }
        )
    }

    const handleDelete = async (event) => {
        event.stopPropagation();
        onDelete && onDelete(subcontractor.idterz)
    }

    const handleOpenSubcontractorProcesses = (event) => {
        event.stopPropagation();
        navigate(`../${subcontractor.idterz}`);
    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">
                {subcontractor.ragsoctz}
            </td>
            <td className="pl-2 "> {subcontractor.contatto} </td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaCubes
                        title="Lavorazioni fornitore"
                        onClick={handleOpenSubcontractorProcesses}
                        className="cursor-pointer hover:text-green-400"
                    />
                    <FaTrashAlt
                        title="Cancella terzista"
                        onClick={handleDelete}
                        className="cursor-pointer hover:text-red-500"/>
                </div>
            </td>
        </TableRow>
    )
}

export default SubcontractorRow;
