import React from 'react';
import {Outlet} from 'react-router-dom';
import Layout from "../components/UI/Layout";
import Navigation from "../components/Reports/Navigation";

const Reports = () => {
    return (
        <Layout title="Stampe">
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <Navigation />
                <Outlet />
            </div>
        </Layout>
    );
};

export default Reports;