import React from 'react';
import TableRow from "../../Queries/General/TableRow";
import {formatInt, formatPrice} from "../../../utilities/data/parser";
import LotLink from "../../Lots/LotLink";

const SalesRow = ({row}) => {
    return (
        <TableRow>
            <td><LotLink movements={true} nrlotto={row?.nrlotto}> {row?.nrlotto}</LotLink></td>
            <td>{row?.buying_article}</td>
            {/*<td className="border-l border-r"></td> /!*rif*!/*/}
            <td>{row?.sales_article}</td>
            <td>{row?.client}</td>
            <td className="text-right pr-1">{formatPrice(row?.performance)}</td>
            <td className="text-right pr-1">{formatPrice(row?.costs_per_mq)}</td>
            <td className="text-right pr-1">{formatPrice(row?.sold_per_mq)}</td>
            <td className="text-right pr-1">{formatPrice(row?.diff_percentage)}</td>
            <td className="text-right pr-1">{formatPrice(row?.sold_mq)}</td>
            <td className="text-right pr-1">{formatPrice(row?.total_cost)}</td>
            <td className="text-right pr-1">{formatPrice(row?.total_profit)}</td>
            <td className="text-right pr-1">{formatPrice((row?.total_cost - row?.total_profit))}</td>
            <td className="text-right pr-1">{formatInt(row?.nrpz)}</td>
        </TableRow>
    );
};

export default SalesRow;