import React from 'react';
import TableRow from "../UI/table/TableRow";
import {FaTag, FaTrashAlt} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useRegistry} from "../../utilities/contexts/registry-context";

const ClientRow = ({client, onClick, onDelete, ...props}) => {
    const {setCurrentClient} = useRegistry()
    const navigate = useNavigate()
    const handleClick = async () => {
        onClick && onClick(client.idcl);
    }
    const handleDelete = async (event) => {
        event.stopPropagation();
        onDelete && onDelete(client.idcl)
    }
    const openClientArticles = (event) => {
        event.stopPropagation();
        setCurrentClient(client);
        navigate(`../${client.idcl}`)
    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">
                <div>{client && client.ragsoccl ? client.ragsoccl : ""}</div>
            </td>
            <td className="pl-2 ">{client.contatto}</td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTag
                        title="Listino Articoli"
                        onClick={openClientArticles}
                        className="cursor-pointer hover:text-green-400"/>
                    <FaTrashAlt
                        title="Cancella cliente"
                        onClick={handleDelete}
                        className="cursor-pointer hover:text-red-500"/>
                </div>
            </td>
        </TableRow>
    )
}

export default ClientRow;
