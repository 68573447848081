import React from 'react';
import Layout from "../components/UI/Layout";
import {Outlet} from "react-router-dom";

const Subcontractors = () => {
    return(
        <Layout title="Terzisti">
            <Outlet/>
        </Layout>
    )
}

export default Subcontractors;
