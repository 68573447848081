import React from 'react';
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import SearchSelectDocuments from "../../../../UI/Selects/SearchSelectDocuments";
import Input from "../../../../UI/Input";
import RowAligned from "../../../../UI/Structure/RowAligned";
import SelectDocument from "../../../../UI/Selects/SelectDocument";

const DocumentInput = ({type, setType, number, setNumber, date, setDate}) => {

    const handleDocTypeChange = (e) => {
        setType(e.target.value);
    }

    return (
        <RowAligned className="items-start w-full gap-2 mb-2">
            <VerticalLabelledField label="Documento" className="flex-2">
                <SelectDocument className="w-full" onChange={handleDocTypeChange} type={type} />
            </VerticalLabelledField>
            <VerticalLabelledField label="Numero" className="flex-1">
                <Input type="text"
                       className="w-full"
                       value={number}
                       onChange={(e) => setNumber(e.target.value)}
                       placeholder="Numero"/>
            </VerticalLabelledField>
            <VerticalLabelledField label="Data">
                <Input type="date"
                       value={date}
                       required={true}
                       onChange={(e) => setDate(e.target.value)}
                />
            </VerticalLabelledField>
            <div className="text-gray-500 text-sm italic">azienda: (padre)</div>
        </RowAligned>
    );
};

export default DocumentInput;