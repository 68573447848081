import React from 'react';
import reportReducer, {defaultState} from "../reducers/report-reducer";
import actions from "../actions/reports";

const ReportContext = React.createContext();

function useReportContextValue() {
    const [state, dispatch] = React.useReducer(reportReducer, defaultState);

    const {salesData, salesFormData,
        costsData, costsFormData,
        movementListData, movementListFormData,
        journalData, journalFormData,
        ledgerData, ledgerFormData} = state;

    const loadSales = React.useCallback((list, totals) => dispatch(actions.loadSales(list, totals)), []);
    const setSalesForm = React.useCallback((date_from, date_to, client, nrlotto) => dispatch(actions.setSalesForm(date_from, date_to, client, nrlotto)), []);
    const loadCosts = React.useCallback((list, totals) => dispatch(actions.loadCosts(list, totals)), []);
    const setCostsForm = React.useCallback((date_from, date_to, client, nrlotto) => dispatch(actions.setCostsForm(date_from, date_to, client, nrlotto)), []);
    const loadMovements = React.useCallback((list, totals) => dispatch(actions.loadMovements(list, totals)), []);
    const setMovementsForm = React.useCallback((date_from, date_to, nrlotto) => dispatch(actions.setMovementsForm(date_from, date_to, nrlotto)), []);
    const loadJournal = React.useCallback((list, totals) => dispatch(actions.loadJournal(list, totals)), []);
    const setJournalForm = React.useCallback((date_from, date_to, nrlotto, totals) => dispatch(actions.setJournalForm(date_from, date_to, nrlotto, totals)), []);
    const loadLedger = React.useCallback((list, totals) => dispatch(actions.loadLedger(list, totals)), []);
    const setLedgerForm = React.useCallback((date_from, date_to, nrlotto) => dispatch(actions.setLedgerForm(date_from, date_to, nrlotto)), []);

    return React.useMemo(() => {
        return {
            salesData, loadSales, salesFormData, setSalesForm,
            costsData, loadCosts, costsFormData, setCostsForm,
            movementListData, loadMovements, movementListFormData, setMovementsForm,
            journalData, loadJournal, journalFormData, setJournalForm,
            ledgerData, loadLedger, ledgerFormData, setLedgerForm,
        }
    }, [
        salesData, loadSales, salesFormData, setSalesForm,
        costsData, loadCosts, costsFormData, setCostsForm,
        movementListData, loadMovements, movementListFormData, setMovementsForm,
        journalData, loadJournal, journalFormData, setJournalForm,
        ledgerData, loadLedger, ledgerFormData, setLedgerForm,
    ]);
}

function ReportProvider(props) {
    const value = useReportContextValue();
    return <ReportContext.Provider value={value} {...props} />
}

function useReport() {
    const context = React.useContext(ReportContext);
    if(!context) {
        throw new Error("useReport must be used within a ReportProvider");
    }
    return context;
}

export {
    ReportProvider,
    useReport,
}