import React from 'react';
import useAsync from "../../utilities/customHooks/async-call";
import {doGetColor} from "../../logics/registry";
import TableRow from "../UI/table/TableRow";
import {FaTrashAlt} from "react-icons/fa";

const ColorRow = ({color, onClick, onDelete, ...props}) => {

    const handleClick = async () => {
        onClick && onClick(color.idcol);
    }

    const handleDelete = async (event) => {
        event.stopPropagation();
        onDelete && onDelete(color.idcol)
    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">{color.codcol}</td>
            <td className="pl-2 ">{color.nomecol}</td>
            <td className="pl-2 ">{color.tipcol.nometipocol}</td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt
                        title="Cancella Lavorazione"
                        onClick={handleDelete}
                        className="cursor-pointer hover:text-red-500"
                    />
                </div>
            </td>
        </TableRow>
    )
};

export default ColorRow;
