import React from 'react';
import {CSVLink} from "react-csv";
import {useReport} from "../../../utilities/contexts/report-context";
import {formatInt, formatPrice} from "../../../utilities/data/parser";
import HorizontalLabelledField from "../../UI/Fields/HorizontalLabelledField";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import CsvButton from "../../UI/CSVButton";
import RowAligned from "../../UI/Structure/RowAligned";
import PrintButton from "../../UI/PrintButton";

const headers = [
    {label: "Nr Lotto", key: ""},
    {label: "Provenienza", key: ""},
    {label: "Dornitore", key: ""},
    {label: "Articolo", key: ""},
    {label: "Cliente", key: ""},
    {label: "Resa (Mq)", key: ""},
    {label: "Resa (Pq)", key: ""},
    {label: "Resa media vendite", key: ""},
    {label: "Costo €/mq", key: ""},
    {label: "Costo £/pq", key: ""},
    {label: "Ve €/mq", key: ""},
    {label: "Ve £/pq", key: ""},
    {label: "Diff. %", key: ""},
    {label: "N. Mq Vend.", key: ""},
    {label: "Costo Mat.Prima", key: ""},
]

const CostsTotals = () => {

    const {costsData} = useReport();

    const handlePrint = () => {

    }

    const getCsvFileName = () => {

    }


    return (
        <div className="flex flex-row justify-between gap-2 border-t border-black p-1">
            <RowAligned>
                <PrintButton onClick={handlePrint}/>
                {costsData.list &&
                <CSVLink data={costsData.list}
                         filename={getCsvFileName()}
                         separator={";"}
                         headers={headers}>
                    <CsvButton />
                </CSVLink>
                }

            </RowAligned>

            <VerticalLabelledField label="Totali">
                <div className="flex flex-row gap-2">
                    <HorizontalLabelledField label="Nrpz">
                        {costsData.totals?.nrpz ? formatInt(costsData.totals?.nrpz): "..."}
                    </HorizontalLabelledField>
                    <HorizontalLabelledField label="Costi">
                        {formatPrice(costsData.totals?.costs)}€
                    </HorizontalLabelledField>
                    <HorizontalLabelledField label="Vendite">
                        {formatPrice(costsData.totals?.sales)}€
                    </HorizontalLabelledField>
                    <HorizontalLabelledField label="Profitti">
                        {formatPrice(costsData.totals?.profit)}€
                    </HorizontalLabelledField>
                </div>
            </VerticalLabelledField>
        </div>
    );
};

export default CostsTotals;