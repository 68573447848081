import React from 'react';
import Section from "../../UI/Structure/Section";
import RowForm from "../../UI/Form/RowForm";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import BaseInput from "../../UI/BaseInput";
import {useReport} from "../../../utilities/contexts/report-context";

const Search = ({onSubmit}) => {

    const {movementListFormData, setMovementsForm} = useReport();

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit && onSubmit()
    };

    const handleStartChange = (e) => {
        setMovementsForm(e.target.value, movementListFormData.date_to, movementListFormData.nrlotto);
    }
    const handleEndChange = (e) => {
        setMovementsForm(movementListFormData.date_from, e.target.value, movementListFormData.nrlotto);
    }
    const handleLotChange = (e) => {
        setMovementsForm(movementListFormData.date_from, movementListFormData.date_to, e.target.value);
    }

    return (
        <Section collapsable={true} label="Elenco completo movimenti">
            <RowForm onSubmit={handleSubmit}>
                <VerticalLabelledField label="Dal">
                    <BaseInput type="date" value={movementListFormData.date_from} onChange={handleStartChange} />
                </VerticalLabelledField>
                <VerticalLabelledField label="Al">
                    <BaseInput type="date" value={movementListFormData.date_to} onChange={handleEndChange} />
                </VerticalLabelledField>
                <VerticalLabelledField label="Nr. lotto">
                    <BaseInput placeholder="Numero lotto" value={movementListFormData.nrlotto} onChange={handleLotChange} />
                </VerticalLabelledField>
            </RowForm>
        </Section>
    );
};

export default Search;