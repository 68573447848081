import React, {useEffect, useRef, useState} from "react";
import Button from "../../UI/Button";
import BaseInput from "../../UI/BaseInput";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import FormTitle from "../../Lots/Movements/Creation/UI/FormTitle";
import SelectProcess from "../../UI/Selects/SelectProcess";
import SelectMeasureUnits from "../../UI/Selects/SelectMeasureUnits";
import {useParams} from "react-router-dom";

const SubcontractorProcessesForm = ({selected, onSubmit}) => {
    const [selectedProcess, setSelectedProcess] = useState(null);
    const [measureUnits, setMeasureUnits] = useState(
        {
            um1: selected?.um1 ? selected.um1.idum : "",
            um2: selected?.um2 ? selected.um2.idum : "",
            um3: selected?.um3 ? selected.um3.idum : "",
        })

    const formRef = useRef();
    const routeParams = useParams();

    const handleSelectProcess = (selectedProcess) => {
        setSelectedProcess(selectedProcess);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = formRef.current.data.value ? formRef.current.data.value : new Date().toISOString().slice(0, 10);
        let prezzo1 = formRef.current.prezzo1.value ?? 0;
        let prezzo2 = formRef.current.prezzo2.value ? formRef.current.prezzo2.value : 0;
        let prezzo3 = formRef.current.prezzo3.value ? formRef.current.prezzo3.value : 0;
        let idum1 = measureUnits?.um1 ? measureUnits?.um1 : 0
        let idum2 = measureUnits?.um2 ? measureUnits?.um2 : 0
        let idum3 = measureUnits?.um3 ? measureUnits?.um3 : 0
        let note = formRef.current.note.value;
        const idterz = routeParams?.idterz;
        const idlav = selectedProcess?.id;

        if (selected) {
            const params = {
                data,
                prezzo1,
                prezzo2,
                prezzo3,
                idum1,
                idum2,
                idum3,
                note,
                idterz,
                idlav
            }
            onSubmit && onSubmit(params);
        } else {
            let params = new URLSearchParams();
            params.append('data', data);
            params.append('note', note);
            params.append('prezzo1', prezzo1);
            params.append('prezzo2', prezzo2);
            params.append('prezzo3', prezzo3);
            params.append('idum1', idum1);
            params.append('idum2', idum2);
            params.append('idum3', idum3);
            params.append("idterz", idterz)
            params.append("idlav", idlav)
            onSubmit && onSubmit(params);
        }
    }

    //initial values
    useEffect(() => {
        if (selected) {
            if (selected?.lav) {
                const lav = {id: selected?.lav.idlav, name: selected.lav.nomelav}
                setSelectedProcess(lav)
            }
            if (selected.prezzo1) formRef.current.prezzo1.value = selected.prezzo1;
            if (selected.prezzo2) formRef.current.prezzo2.value = selected.prezzo2;
            if (selected.prezzo3) formRef.current.prezzo3.value = selected.prezzo3;
            if (selected.data) formRef.current.data.value = selected.data ? selected.data : new Date().toISOString().slice(0, 10);
            if (selected.note) formRef.current.note.value = selected.note;
            if (selected.um1) formRef.current.um1.value = selected.um1.idum ? selected.um1.idum : "";
            if (selected.um2) formRef.current.um2.value = selected.um2.idum ? selected.um2.idum : "";
            if (selected.um3) formRef.current.um3.value = selected.um3.idum ? selected.um3.idum : "";
        }
    }, [selected]);

    return (
        <>
            <FormTitle>{selected ? "Modifica" : "Crea"} lavorazione</FormTitle>
            <form onSubmit={handleSubmit}
                  className="flex flex-col gap-2"
                  ref={formRef}
                  style={{minWidth: "300px"}}>
                {!selected &&
                    <VerticalLabelledField label="lavorazioni">
                        <SelectProcess value={selectedProcess} onChange={handleSelectProcess} disabled={!!selected}/>
                    </VerticalLabelledField>
                }
                <VerticalLabelledField label="Data">
                    <BaseInput
                        name="data"
                        type="date"
                        placeholder="wut"
                    />
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo nr. 1">
                    <div className="flex flex-row gap-2">
                        <BaseInput placeholder="Prezzo nr. 1" name="prezzo1"/>
                        <SelectMeasureUnits
                            value={measureUnits?.um1}
                            onChange={(value) => setMeasureUnits(prevState => ({...prevState, um1: value}))}
                            name="um1"/>
                    </div>
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo nr. 2">
                    <div className="flex flex-row gap-2">
                        <BaseInput placeholder="Prezzo nr. 2" name="prezzo2"/>
                        <SelectMeasureUnits
                            value={measureUnits?.um2}
                            onChange={(value) => setMeasureUnits(prevState => ({...prevState, um2: value}))}
                            name="um2"/>
                    </div>
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo nr. 3">
                    <div className="flex flex-row gap-2">
                        <BaseInput placeholder="Prezzo nr. 3" name="prezzo3"/>
                        <SelectMeasureUnits
                            value={measureUnits?.um3}
                            onChange={(value) => setMeasureUnits(prevState => ({...prevState, um3: value}))}
                            name="um3"/>
                    </div>
                </VerticalLabelledField>
                <VerticalLabelledField label="Note">
                    <BaseInput placeholder="Note" name="note"/>
                </VerticalLabelledField>
                <Button>{selected ? "Modifica" : "Crea"}</Button>
            </form>
        </>
    );
};

export default SubcontractorProcessesForm;
